import color from 'core/libs/color';

const colors = {
  divider: '#e2e2e2',
  active1: '#3037eb',
  get active2() {
    return this.active1;
  },
  accent: '#f6c5af',
  error: '#cc3145',
  yellow1: '#fff3ca',
  yellow2: '#f4d56e',
  grey: '#a2a2a2',
  grey2: '#636363',
  content: '#fff',
  get input() {
    return this.content;
  },
  card: '#fff',
  primary: '#32292f',
  black: '#000',
  white: '#FFF',
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.2).string();
  },
  get cardHover() {
    return this.active1;
  },
  get opacityActive1() {
    return color(this.active1).alpha(0.5).string();
  },
  get theme() {
    return this.active1;
  },
  get dates() {
    return color(this.black).alpha(0.37).string();
  },
  get expertBody() {
    return color(this.black).alpha(0.6).string();
  },
};

export default {
  colors,

  controls: {
    button: {
      primary: {
        idle: {
          color: colors.white,
          background: colors.active1,
        },
        hover: {
          color: colors.white,
          background: '#ea8271',
        },
        active: {
          color: colors.white,
          background: '#ea8271',
        },
      },
      secondary: {
        idle: {
          border: `1px solid ${colors.hint}`,
          color: colors.hint,
        },
        hover: {
          background: 'transparent',
        },
      },
    },
    paginatron: {
      outerButtons: {
        innerBorder: `1px solid ${colors.hint}`,
        hover: {
          color: colors.active1,
          background: 'transparent',
        },
      },
    },
  },
};
