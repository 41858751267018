const baseType = {
  maxWidth: 860,
  aspectRatio: 860 / 280,
  padding: 50,
  mobile: {
    maxWidth: 520,
    aspectRatio: 520 / 260,
    padding: 20,
  },
};

export default {
  0: baseType,
  1: {
    maxWidth: 860,
    aspectRatio: 860 / 280,
    showButton: true,
    showIcon: false,
    padding: 30,
    mobile: {
      maxWidth: 520,
      aspectRatio: 520 / 260,
      showButton: true,
      showIcon: false,
      padding: 20,
    },
  },
  2: {
    maxWidth: 410,
    aspectRatio: 410 / 600,
    showIcon: false,
    trimTitle: true,
    mobile: {
      maxWidth: 520,
      aspectRatio: 520 / 260,
      showIcon: false,
    },
  },
  3: {
    ...baseType,
    mobile: {
      ...baseType.mobile,
      bottomMargin: -20,
    },
  },
};
