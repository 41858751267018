import PropTypes from 'prop-types';

import StickyPortal from 'core/components/StickyPortal';
import Bouesque from 'core/components/Bouesque';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import SelfPromo from 'core/components/SelfPromo';

import { VERTICAL } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';
import { Smi2Vertical, Smi24Vertical } from 'site/components/Partners';
import CompatibilityWidget from 'site/components/CompatibilityWidget';

import {
  AdPlace2,
  AdPlace3,
} from 'site/components/Ads/desktop';


function InnerSideColumn(props) {
  const {
    showBoesque,
    showSmi24Vertical,
    showCompatibility,
    isCompatibilityPage,
  } = props;

  return (
    <>
      {showBoesque && (
        <IndentWrap bottom={VERTICAL}>
          <Bouesque
            height='42px'
            radius='6px'
            withoutIcon
          />
        </IndentWrap>
      )}
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={VERTICAL}>
          <AdPlace2 />
        </AdWrapper>
        <IndentWrap bottom={VERTICAL}>
          <SelfPromo />
        </IndentWrap>
        {showCompatibility && (
          <IndentWrap bottom={VERTICAL}>
            <CompatibilityWidget side />
          </IndentWrap>
        )}
        <IndentWrap bottom={VERTICAL}>
          <Smi2Vertical />
        </IndentWrap>
      </StickyPortal>
      <StickyPortal>
        {!isCompatibilityPage && (
          <AdWrapper bottom={VERTICAL}>
            <AdPlace3 />
          </AdWrapper>
        )}
        {showSmi24Vertical && !isCompatibilityPage &&  (
          <IndentWrap bottom={VERTICAL} top={VERTICAL}>
            <Smi24Vertical />
          </IndentWrap>
        )}
      </StickyPortal>
    </>
  );
}

InnerSideColumn.defaultProps = {
  showBoesque: true,
  showSmi24Vertical: true,
  showCompatibility: false,
};

InnerSideColumn.propTypes = {
  showBoesque: PropTypes.bool,
  showSmi24Vertical: PropTypes.bool,
  showCompatibility: PropTypes.bool,
  isCompatibilityPage: PropTypes.bool,
};

export default withBreakpoint(InnerSideColumn);
