import PropTypes from 'prop-types';
import { Mobile, Desktop } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';

import {
  Context,
} from 'site/components/Ads/desktop';

import {
  Footer as BanFooter,
} from 'site/components/Ads/mobile';

export default function AdFooter({ contextCount }) {
  return (
    <AdWrapper top={40}>
      <Mobile>
        <BanFooter />
      </Mobile>
      <Desktop>
        <Context count={contextCount} />
      </Desktop>
    </AdWrapper>
  );
}

AdFooter.propTypes = {
  contextCount: PropTypes.number,
};

AdFooter.defaultProps = {
  contextCount: 1,
};
