import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';
import AuthorPage from 'core/components/AuthorPage';

import { filterRequiredParams } from 'core/utils/api';

import {
  authorFetcher,
  topicsFetcher,
} from 'core/fetchers/authorPage';

import BasePage from 'site/components/BasePage';
import { VERTICAL } from 'site/components/Indents';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import { IndentWrap } from 'site/components/Wrappers';

const limit = 15;

function Author({ topics, author, isMobile }) {
  const spacing = isMobile ? 20 : VERTICAL;
  const sideSpacings = {
    right: isMobile ? spacing : 0,
    left: isMobile ? spacing : 0,
  };

  return (
    <BasePage>
      <IndentWrap
        top={spacing}
        {...sideSpacings}
      >
        <AuthorPage
          author={author}
          topics={topics}
          limit={limit}
          columns={3}
          grid
          card={isMobile ? Card3 : Card2}
          interitemSpacing={isMobile ? 20 : 40}
        />
      </IndentWrap>
    </BasePage>
  );
}

Author.propTypes = {
  topics: PropTypes.object,
  author: PropTypes.object,
  isMobile: PropTypes.bool,
};


const dataProvider = resolve({
  author: authorFetcher(),
  topics: topicsFetcher({
    include: 'image',
    limit: limit,
    fields: filterRequiredParams([Card2, Card3], 'fields'),
  }),
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
