import PropTypes from 'prop-types';
import coreMarkdown from 'core/components/TopicContent/blocks/markdown';

import styles from './index.styl';

export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  return (
    <div className={styles.markdown}>
      <style jsx>{`
        ${styles.markdown}
          :global(ol)
          :global(ul)
            :global(li):before
              font-family ${parentProps.theme.fonts.display}
              color ${parentProps.theme.colors.active1}
      `}
      </style>
      {coreMarkdown(block, parentProps, ctx)}
    </div>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
