import LegalPage from 'core/components/LegalPage';

export default function Legal() {
  return (
    <LegalPage
      description={{
        'privacy': 'Политика конфиденциальности портала Страсти в отношении обработки персональных данных',
        'terms-of-use': 'Условия использования ресурса Страсти, информация для пользователей',
      }}
    />
  );
}
