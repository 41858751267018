import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Desktop, Mobile } from 'core/components/breakpoint';

export default function BreakpointSwitcher(props) {
  const {
    mobile,
    desktop,
  } = props;

  return (
    <Fragment>
      {mobile && (
        <Mobile>
          {mobile}
        </Mobile>
      )}
      {desktop && (
        <Desktop>
          {desktop}
        </Desktop>
      )}
    </Fragment>
  );
}

BreakpointSwitcher.propTypes = {
  /** Контент, отображаемый на мобилке */
  mobile: PropTypes.node,
  /** Контент, отображаемый на десктопе */
  desktop: PropTypes.node,
};
