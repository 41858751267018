import cx from 'classnames';
import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import styles from './index.styl';

export default function ContentSpecNative(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['content-spec-native'], 'adv-native content-spec-native')}>
      <GlobalStyles {...props} />
      <Component {...props} />
      <style jsx global>{`
        .content-spec-native
          .ad_native_wrapper
            background-color ${props.theme.colors.layout}
          .ad_native_content-wrapper
            background-color ${props.theme.colors.card}
          .ad_native_img
            background-color ${props.theme.colors.card}

          .ad_native_title
          .ad_native_desc
            color ${props.theme.colors.primary}

          .ad_native_desc
            font 14px/20px ${props.theme.fonts.nuance}

          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.display}

          .ad_label__text
          .adv_info__text
            color ${props.theme.colors.hint}
            font 14px/1 ${props.theme.fonts.nuance}
          img
            background-color ${props.theme.colors.active1}
      `}
      </style>
    </div>
  ));
}
