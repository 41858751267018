/*
* Для транслита имен используется тот же пакет (той же версии),
* что и в админке для преобразования ссылок, чтобы не возникали конфликты
*/
import slug from 'speakingurl';

const normilizeNameList = label => ({
  value: label,
  label,
  translit: slug(label),
});

const fNames = [
  'Анастасия',
  'Юлия',
  'Анна',
  'Елена',
  'Ольга',
  'Екатерина',
  'Ирина',
  'Татьяна',
  'Алина',
  'Наталья',
  'Дарья',
  'Марина',
  'Мария',
  'Виктория',
  'Светлана',
  'Яна',
  'Кристина',
  'Полина',
  'Алена',
  'Ксения',
  'Валерия',
  'Оксана',
  'Диана',
  'Ангелина',
  'Елизавета',
  'Вероника',
  'Надежда',
  'Александра',
  'Любовь',
  'Евгения',
];

export const femaleNames = fNames.sort().map(normilizeNameList);

const mNames = [
  'Александр',
  'Сергей',
  'Андрей',
  'Дмитрий',
  'Алексей',
  'Максим',
  'Евгений',
  'Артем',
  'Никита',
  'Денис',
  'Кирилл',
  'Иван',
  'Игорь',
  'Роман',
  'Павел',
  'Владимир',
  'Михаил',
  'Антон',
  'Валерий',
  'Олег',
  'Николай',
  'Егор',
  'Вадим',
  'Владислав',
  'Виталий',
  'Руслан',
  'Виктор',
  'Константин',
  'Даниил',
  'Юрий',
];

export const maleNames = mNames.sort().map(normilizeNameList);
