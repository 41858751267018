import { compose, defaultProps, withProps } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import Native1Styles from './natives/Native1';
import MainPageNativeStyles from './natives/MainPageNative';
import RubricPageNativeStyles from './natives/RubricPageNative';
import Native3Styles from './natives/Native3';

import styles from './desktop.styl';


export const Billboard = bindProps({
  name: 'Billboard',
  blockId: '432500906',
  height: 250,
  options: {
    'p1': 'btqzl',
    'p2': 'y',
    'pct': 'c',
  },
})(AdBillboard);

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  blockId: '432500854',
  options: {
    'p1': 'btqzq',
    'p2': 'emiu',
    'pct': 'a',
    'pli': 'b',
    'plp': 'c',
    'pop': 'a',
  },
})(Ad);

export const Shtorka = bindProps({
  name: 'Shtorka',
  blockId: '434532186',
  options: {
    'p1': 'bvnez',
    'p2': 'exne',
    'pct': 'a',
  },
})(Ad);

export const AdPlace2 = bindProps({
  name: '240x400',
  width: 300,
  height: 600,
  blockId: '432500902',
  stickyTemp: 3,
  options: {
    'p1': 'btqzm',
    'p2': 'emhk',
    'pct': 'c',
  },
})(Ad);

export const AdPlace3 = bindProps({
  name: '240x400_2nd',
  width: 300,
  height: 600,
  lazy: true,
  blockId: '432500882',
  options: {
    'p1': 'btqzn',
    'p2': 'ewqs',
    'pct': 'a',
  },
})(Ad);

export const Context = compose(
  bindProps({
    name: 'Context',
    lazy: true,
    height: 80,
    blockId: '432500846',
    options: {
      'p1': 'btrau',
      'p2': 'ewzc',
      'pct': 'a',
    },
  }),
  defaultProps({ count: 1 }),
  withProps(({ count }) => ({ manualPuids: { puid44: `context_item${count}` } })),
)(Ad);

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored',
    blockId: '445549670',
    options: {
      'p1': 'bwujx',
      'p2': 'fomx',
      'pct': 'a',
    },
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = bindProps({
  name: '100%x240',
  width: 600,
  height: 240,
  lazy: true,
  blockId: '432500876',
  options: {
    'p1': 'btqzo',
    'p2': 'fcuz',
    'pct': 'c',
  },
})(Ad);

export const Parallax = bindProps({
  name: 'Parallax',
  width: 1200,
  height: 250,
  lazy: true,
  blockId: '445548674',
  options: {
    'p1': 'bwujw',
    'p2': 'fhoe',
    'pct': 'a',
  },
})(Ad);

export const AdCenter = bindProps({
  name: 'Center',
  width: 600,
  height: 240,
  blockId: '445547580',
  lazy: true,
  options: {
    'p1': 'bwujv',
    'p2': 'fomw',
    'pct': 'a',
  },
})(Ad);

export const InRead = bindProps({
  name: 'inRead',
  width: 600,
  height: 300,
  blockId: '432500826',
  options: {
    'p1': 'btqzp',
    'p2': 'fcvb',
    'pct': 'a',
  },
})(props => <div className={styles.inread}><Ad {...props} /></div>);

export const Native1 = compose(
  bindProps({
    name: 'Native1',
    width: 260,
    height: 260,
    blockId: '433694186',
    options: {
      'p1': 'bunzf',
      'p2': 'fhzr',
      'pct': 'a',
    },
  }),
  Native1Styles,
)(Ad);

export const MainPageNative2 = compose(
  bindProps({
    name: 'MainPage Native2',
    blockId: '433694212',
    lazy: true,
    options: {
      'p1': 'bunzg',
      'p2': 'fhzs',
      'pct': 'a',
    },
  }),
  MainPageNativeStyles
)(Ad);

export const RubricPageNative2 = compose(
  bindProps({
    name: 'RubricPage Native2',
    blockId: '433694212',
    lazy: true,
    options: {
      'p1': 'bunzg',
      'p2': 'fhzs',
      'pct': 'a',
    },
  }),
  RubricPageNativeStyles,
)(Ad);

export const Native3 = compose(
  bindProps({
    name: 'Native3',
    width: 300,
    blockId: '454774708',
    lazy: true,
    options: {
      'p1': 'bxckd',
      'p2': 'fjgk',
      'pct': 'a',
    },
  }),
  Native3Styles,
)(Ad);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '480359585',
  disableAutoInit: true,
})(Ad);
