import Page from 'core/components/Page';
import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';

import AdFooter from 'site/components/Ads/AdFooter';

import mainResolver from './mainTopics';
import MainPageContent from './MainPageContent';


function MainPage(props) {
  return (
    <Page description='Женский онлайн-журнал Страсти. Свежие новости мира моды, красоты, шопинга, шоу-бизнеса и отношений, эксклюзивные интервью и репортажи'>
      <MainPageContent {...props} />
      <AdFooter contextCount={3} />
    </Page>
  );
}

export default withBreakpoint(withPageHocs(mainResolver)(MainPage));
