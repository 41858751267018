import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function TitleCardS(props) {
  const {
    theme,
    children,
  } = props;

  return (
    <div className={styles.title}>
      <style jsx>{`
        .${styles.title}
          font 400 15px/19px ${theme.fonts.display}
          color ${theme.colors.black}
          transition color ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.active1}
      `}
      </style>
      {children}
    </div>
  );
}

TitleCardS.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(TitleCardS);
