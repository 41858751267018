import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTabs from 'core/components/withTabs';
import withTheme from 'core/components/theme';

import Feed from 'core/components/Feed';
import Link from 'core/components/Link';

import Title2 from 'site/components/Title2';
import BackTitle from 'site/components/BackTitle';
import { IndentWrap } from 'site/components/Wrappers';

import { Card3Type4, Card3Type7 } from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';


const tabs = [
  {
    title: 'Салаты',
    tag: 'salaty',
  }, {
    title: 'Горячее',
    tag: 'goryachee',
  }, {
    title: 'Десерты',
    tag: 'deserty',
  }, {
    title: 'Напитки',
    tag: 'napitki',
  },
].map(t => ({
  ...t,
  radius: '30px',
  size: 'small2',
}));

function Recipes({ recipes, videorecipes, salaty, theme, isMobile }) {
  const scope = resolveScopedStyles((
    <scope>
      <style jsx>{`
        .link
          padding ${theme.controls.button.const.sizes.small.padding}
          font ${theme.controls.button.const.sizes.small.font}
      `}
      </style>
      <style jsx>{`
        .link
          display inline-block
          white-space nowrap
          cursor pointer
          text-decoration none
          &:hover
            text-decoration none
      `}
      </style>
    </scope>
  ));

  const after = (
    <Link
      to='/food/recepty'
      className={scope.wrapClassNames('link')}
    >
      Все рецепты
    </Link>
  );

  const TabbedRecipes =  withTabs({
    tabs,
    indent: 20,
    after,
    wrap: !isMobile,
    ...isMobile && { justifyContent: 'center' },
    useScrollerOnMobile: true,
  })(RecipesArticles);

  return (
    <BackTitle indentBottom={isMobile ? -30 : 0} title='Рецепты'>
      <IndentWrap bottom={20}>
        <Feed
          content={recipes}
          card={Card4}
          interitemSpacing={isMobile ? 20 : 40}
        />
      </IndentWrap>
      <IndentWrap left={isMobile ? 20 : 0} right={isMobile ? 20 : 0}>
        <TabbedRecipes content={salaty} isMobile={isMobile} />
        <Feed
          content={videorecipes}
          card={isMobile ? Card3Type7 : Card3Type4}
          interitemSpacing={20}
          grid
          columns={2}
          title='Видеосоветы'
          headerComponent={Title2}
        />
      </IndentWrap>
      <scope.styles />
    </BackTitle>
  );
}

Recipes.propTypes = {
  recipes: PropTypes.array,
  videorecipes: PropTypes.array,
  salaty: PropTypes.array,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(withBreakpoint(Recipes));

function RecipesArticles({ content, isMobile, activeIndex, tag }) {
  /**
   * Для первой вкладки получаем топики на уровне страницы,
   * уже на клиенте получаем для других вкладок при переключении.
   */
  const contentProps = activeIndex === 0
    ? { content }
    : {
      tag,
      limit: 4,
    };

  return (
    <IndentWrap bottom={40}>
      <Feed
        card={isMobile ? Card3Type7 : Card3Type4}
        interitemSpacing={20}
        grid
        columns={2}
        {...contentProps}
      />
    </IndentWrap>
  );
}

RecipesArticles.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.number,
  tag: PropTypes.string,
  isMobile: PropTypes.bool,
};
