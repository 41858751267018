import React from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import TitleCardS from 'site/components/TitleCardS';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = [];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});

function Card1(props) {
  const {
    content,
    position,
    type,
    theme,
  } = props;

  const {
    showImage,
    showPosition,
  } = mapTypes[type] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  return (
    <Link
      to={link}
      type='secondary'
      className={styles.link}
    >
      <style jsx>{`
        .${styles.position}
          font 400 32px/40px ${theme.fonts.text}
          color ${theme.colors.active1}
      `}</style>

      <div className={styles.content}>
        { showPosition && (
          <div className={styles.position}>{position + 1}</div>
        )}
        { showImage && (cover || remoteImage) && (
          <div className={styles.image}>
            <Image
              {...cover && { src: cover }}
              {...cover && { previewSrc: previewCover }}
              {...remoteImage && { url: remoteImage }}
              width={100}
              height={100}
            />
          </div>
        )}
        <div>
          <TitleCardS>{listHeadline || headline}</TitleCardS>
        </div>
      </div>
    </Link>
  );
}

Card1.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3]),
  /** Порядковый номер карточки */
  position: PropTypes.number,
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = cardHOC(Card1);

export const Card1Type1 = bindProps({ type: 1 })(Card);
export const Card1Type2 = bindProps({ type: 2 })(Card);

Card.displayName = 'Card1';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
