import PropTypes from 'prop-types';

import CoreDivider from 'core/components/Divider';

export default  function Divider({ top, bottom }) {
  return <CoreDivider style={{ marginTop: top, marginBottom: bottom, width: '100%' }} />;
}

Divider.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
};
