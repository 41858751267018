import { Fragment } from 'react';
import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import { Desktop, Mobile } from 'core/components/breakpoint';

import { HORISONTAL, VERTICAL } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';

import Card4, { Card4Type2 } from 'site/cards/Card4';
import { Card5Type3 } from 'site/cards/Card5';

import { Native1 } from 'site/components/Ads/desktop';


const feedCard = bindProps({ highPriorityImage: true })(Card4Type2);
const drumCardReplacement = bindProps({ highPriorityImage: true })(Card4);

export default function Glavnoe({ content, news }) {
  const topics = content.concat(news);
  const first = topics.slice(0, 2);
  const second = topics.slice(2);

  return (
    <Fragment>
      <Desktop>
        <Feed
          heroCard={feedCard}
          content={first}
          card={Card4Type2}
          columns={2}
          interitemSpacing={HORISONTAL}
          grid
        />
        <IndentWrap bottom={VERTICAL} />
        <Feed
          content={second}
          card={Card5Type3}
          columns={3}
          interitemSpacing={HORISONTAL}
          grid
          cardsReplacement={{
            2: replaceCardWithAd(Native1),
          }}
        />
      </Desktop>
      <Mobile>
        <Drum
          content={topics}
          card={Card4}
          itemWidthMobile='100%'
          cardsReplacement={{
            0: drumCardReplacement,
          }}
        />
      </Mobile>
    </Fragment>
  );
}

Glavnoe.propTypes = {
  content: PropTypes.array,
  news: PropTypes.array,
};
