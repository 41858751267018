import cx from 'classnames';
import withTheme from 'core/components/theme';
import GlobalStyles from '../global';
import styles from './index.styl';

export default function ListingSpecSearch(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['listing-spec-search'], 'adv-native listing-spec-search')}>
      <GlobalStyles {...props} />
      <Component {...props} />
      <style jsx global>{`
        .listing-spec-search
          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.nuance}
            :global(.mobile) &
              font bold 18px/1.2 ${props.theme.fonts.nuance}
            color ${props.theme.colors.primary}
            
          .ad_label__text
            font 18px/1.2 ${props.theme.fonts.text}
            color ${props.theme.colors.primary} 
          
          .adv_info__text
            font 12px/1.2 ${props.theme.fonts.nuance}

      `}
      </style>
    </div>
  ));
}
