import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import queryString from 'core/libs/query-string';
import { Fragment } from 'react';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import TopicHeaderTexts from 'core/components/TopicHeaderTexts';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Button from 'core/components/Button';
import withTopic from 'core/components/Topic/withTopic';
import TopicTopline from 'core/components/TopicTopline';

import SocialShare from 'site/components/SocialShare';
import Sponsored from 'site/components/Ads/Sponsored';
import { VerticalIndent, VERTICAL } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';

import TopicHeaderMedia from './TopicHeaderMedia';
import styles from './index.styl';

function TopicHeader(props) {
  const {
    topic: {
      content,
      infinityIndex,
    },
    location: {
      search,
    },
    history,
    isDesktop,
    comments,
    isGameCompare,
  } = props;

  const {
    attributes: {
      is_comments_disabled: isCommentsDisabled,
      link,
    },
  } = content;

  const spacing = isDesktop ? VERTICAL : 20;

  const sideSpacings = {
    right: isDesktop ? 0 : spacing,
    left: isDesktop ? 0 : spacing,
  };

  const page = infinityIndex > 0
    ? 1
    : +queryString.parse(search).page || 1;

  return (
    <div>
      <IndentWrap {...sideSpacings}>
        <TopicTopline />
        <VerticalIndent indent={isDesktop ? 20 : 16} />
        <TopicHeaderTexts capitalizeFirstLetter />
        {!isGameCompare &&
          <Fragment>
            <div className={styles.share}>
              <SocialShare />
              {!isCommentsDisabled && !comments && isDesktop && !isGameCompare && (
                <Button size='small' onClick={() => history.push(link + '/comments')}>Обсудить</Button>
              )}
            </div>
            <VerticalIndent indent={isDesktop ? 24 : 10} />
          </Fragment>
        }
        <Sponsored />
      </IndentWrap>
      {page === 1 && !comments && !isGameCompare && (
        <IndentWrap bottom={isDesktop ? 36 : 24}>
          <TopicHeaderMedia content={content} />
        </IndentWrap>
      )}
    </div>
  );
}

TopicHeader.propTypes = {
  type: PropTypes.string,
  topic: PropTypes.shape({
    content: modelPropTypes(topicAttributes).isRequired,
    infinityIndex: PropTypes.number,
  }),
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  isDesktop: PropTypes.bool,
  comments: PropTypes.bool,
  isGameCompare: PropTypes.bool,
};

export default withBreakpoint(withRouter(withTopic(TopicHeader)));
