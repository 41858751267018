import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import styles from './index.styl';

function TopicMaxWidth({ children, isMobile, theme }) {
  const {
    controls: {
      topicContent,
    },
  } = theme;

  if (isMobile) return <>{children}</>;

  return (
    <div className={styles.topicMaxWidth}>
      <style jsx>{`
        .${styles.topicMaxWidth}
          max-width ${topicContent.widgetsMaxWidth}px
      `}</style>
      {children}
    </div>
  );
}

TopicMaxWidth.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(withBreakpoint(TopicMaxWidth));
