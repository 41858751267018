import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';

import bindProps from 'core/components/bindProps';
import themeSwitcherHOC from 'core/components/themeSwitcher';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import Button from 'core/components/Button';
import AdvTooltip from 'core/components/AdvTooltip';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import TitleCardL from 'site/components/TitleCardL';

import { cardDateFormat } from 'site/utils';
import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'link',
  'published_at',
  'topic_type',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card4(props) {
  const {
    content,
    theme,
    type,
    isMobile,
    buttonTitle,
    breakpoint,
    highPriorityImage,
    location,
    position,
  } = props;

  const {
    headline,
    list_headline: listHeadline,
    link,
    published_at: published,
    topic_type: topicType,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    maxWidth,
    aspectRatio,
    trimTitle,
    bottomMargin = 0,
    showTime,
    showIcon = true,
    showButton,
  } = mapTypes[type][breakpoint] || mapTypes[type] || {};

  const imageProps = {
    ...isMobile && { height: 260 },
    ...maxWidth && { maxWidth },
    ...aspectRatio && { aspectRatio },
    ...highPriorityImage && { fetchPriority: 'high', instant: true },
  };

  const withIcon = showIcon && Icon;
  const isMainPage = location.pathname === '/';
  const shouldShowAdvTooltip = !!(isMainPage && position === 2 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  return (
    <div
      className={cx(
        styles.card,
        withIcon && styles._withIcon,
        trimTitle && styles._trimTitle,
      )}
    >
      <style jsx>{`
        .${styles.card}
          max-width ${maxWidth}px
          margin-bottom ${bottomMargin}px

        .${styles.time}
          font-family ${theme.fonts.nuance}

        .${styles.image}
          &:before
          &:after
            transition opacity ${theme.animations.hover}

        .${styles.content}
          padding ${withIcon && !isMobile ? 50 : 20}px
      `}</style>

      <Link
        to={topicLink}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image}>
          <SmartImage src={cover} {...imageProps} />
        </div>
        <div className={styles.content}>
          {withIcon && (
            <div className={styles.icon}>
              <Icon width={32} height={32} />
            </div>
          )}
          <TitleCardL className={styles.title}>
            {listHeadline || headline}
          </TitleCardL>
          {showTime && (
            <div className={styles.time}>
              {cardDateFormat(published)}
            </div>
          )}
          {showButton && (
            <div className={styles.button}>
              <Button>{buttonTitle}</Button>
            </div>
          )}
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
          position='bottom'
        />
      )}
    </div>
  );
}

Card4.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2]),
  /** @ignore */
  breakpoint: PropTypes.string,
  /** Текст на кнопке (в случае, если она отображается) */
  buttonTitle: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
  /**
  * Использование fetchPriority для загрузки картинки
  */
  highPriorityImage: PropTypes.bool,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
};

Card4.defaultProps = {
  type: 0,
  buttonTitle: 'Пройти тест',
};

const Card = themeSwitcherHOC('dark')(cardHOC(withRouter(Card4)));

export const Card4Type1 = bindProps({ type: 1 })(Card);
export const Card4Type2 = bindProps({ type: 2 })(Card);
export const Card4Type3 = bindProps({ type: 3 })(Card);

Card.displayName = 'Card4';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card4 as StorybookComponent };
