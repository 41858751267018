import PropTypes from 'prop-types';

import Mjolnir from 'core/components/Mjolnir';
import Drum from 'core/components/Drum';

import BackTitle from 'site/components/BackTitle';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';

import { Card4Type1 } from 'site/cards/Card4';
import { Card5Type1 } from 'site/cards/Card5';


export default function Tests({ content }) {
  return (
    <BreakpointSwitcher
      desktop={(
        <BackTitle indentBottom={-10} title='Тесты'>
          <Mjolnir
            content={content}
            card={Card5Type1}
            heroCard={Card4Type1}
            interitemSpacing={10}
          />
        </BackTitle>
      )}
      mobile={(
        <Drum
          content={content}
          card={Card4Type1}
          itemWidthMobile='100%'
        />
      )}
    />
  );
}

Tests.propTypes = {
  content: PropTypes.array,
};
