import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import styles from './index.styl';

function TitleCardL(props) {
  const {
    theme,
    className,
    children,
  } = props;

  return (
    <div className={cx(styles.title, className)}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display}
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.cardHover}
      `}
      </style>
      {children}
    </div>
  );
}

TitleCardL.propTypes = {
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withTheme(TitleCardL);
