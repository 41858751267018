import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import withTabs from 'core/components/withTabs';
import PopularCore from 'core/components/Popular';
import { Mobile } from 'core/components/breakpoint';

import WhiteBlock from 'site/components/WhiteBlock';
import { ListingSpecNewsNative } from 'site/components/Ads/mobile';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { Card1Type2 } from 'site/cards/Card1';

import { TODAY_PERIOD } from 'site/constants';

import styles from './index.styl';


const tabs = [
  {
    title: 'Сегодня',
    days_period: TODAY_PERIOD,
  }, {
    title: 'За неделю',
    days_period: '7',
  },
].map(t => ({
  ...t,
  radius: '30px',
  size: 'small2',
}));

const PopularWithTabs =  withTabs({
  tabs,
  justifyContent: 'center',
  indent: 20,
})(PopularComponent);

const include = filterRequiredParams([Card1Type2], 'include');
const fields = filterRequiredParams([Card1Type2], 'fields');

export default function TabbedPopular({ content }) {
  return (
    <WhiteBlock title='Популярное'>
      <div className={styles.popularWrapper}>
        <PopularWithTabs content={content} />
        <Mobile>
          <AdWrapper top={20}>
            <ListingSpecNewsNative />
          </AdWrapper>
        </Mobile>
      </div>
    </WhiteBlock>
  );
}

TabbedPopular.propTypes = {
  content: PropTypes.array,
};

function PopularComponent({ content, activeIndex, days_period: daysPeriod }) {
  /**
   * Для первой вкладки получаем топики на уровне страницы,
   * уже на клиенте получаем для других вкладок при переключении.
   */
  const contentProps = activeIndex === 0
    ? { topics: content }
    : {
      sort: '-views_count',
      limit: 9,
      days_period: daysPeriod,
      include,
      fields,
    };

  return (
    <PopularCore
      card={Card1Type2}
      interitemSpacing={30}
      {...contentProps}
    />
  );
}

PopularComponent.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.number,
  days_period: PropTypes.string,
};
