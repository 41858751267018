export default {
  0: { imageRatio: 1, trimTitle: 65, borderRadius: 0 },
  1: { imageRatio: 1, showTime: true, additionalPadding: '0 20px', trimTitle: 65, borderRadius: 0 },
  2: { imageSize: 132, imageRatio: 1, trimTitle: 65, borderRadius: 0 },
  3: { imageSize: 380, imageRatio: 1, showButton: true, showAnnounce: true, showBackground: true, borderRadius: 0 },
  4: { imageSize: 90, imageRatio: 1, trimTitle: 65, showBackground: true, borderRadius: 6, additionalPadding: '10px', rounded: true, altIcon: true, headlineWeight: 700 },
  5: { imageSize: 60, imageRatio: 1, showBackground: true, rowReverse: true, borderRadius: 5 },
  6: { imageSize: 132, imageRatio: 275 / 156, showBackground: true, rowReverse: true, borderRadius: 5 },
  7: { imageSize: 60, imageRatio: 1, trimTitle: 65, showBackground: true, rounded: true, altIcon: true, headlineWeight: 700 },
  8: { imageSize: 60, imageRatio: 1, showTime: true, trimTitle: 65, borderRadius: 0 },
};
