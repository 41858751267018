import { compose } from 'core/libs/recompose';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import resolve from 'core/resolver/resolve';

import Card1 from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';
import Card4 from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';
import CardExpert from 'site/cards/CardExpert';

import { MAIN_BLOCK_LIMIT, TODAY_PERIOD } from 'site/constants';


const newsAndVideoInclude = filterRequiredParams([Card3, Card4, Card5], 'include');
const newsAndVideoFields = filterRequiredParams([Card3, Card4, Card5], 'fields');

const mainAndRecipesInclude = filterRequiredParams([Card4], 'include');
const mainAndRecipesFields = filterRequiredParams([Card4], 'fields');

const videorecipiesAndSalatyInclude = filterRequiredParams([Card3], 'include');
const videorecipiesAndSalatyFields = filterRequiredParams([Card3], 'fields');

const mainTopicsProvider = resolve({
  mainTopics: ({ bebopApi, renderError }) => {
    return bebopApi
      .getTopics({
        limit: MAIN_BLOCK_LIMIT,
        list: 'main',
        sort: 'list',
        include: mainAndRecipesInclude,
        fields: mainAndRecipesFields,
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(renderError);
  },
});

const dataProvider = resolve({
  news: ({ mainTopics = [], bebopApi, consoleError, isMobile }) => {
    const newsLimit = isMobile ? 5 : 6;
    const limit = newsLimit + MAIN_BLOCK_LIMIT - mainTopics.length;
    const excludedIds = mainTopics.map(item => item.id).join(',');

    return bebopApi
      .getTopics({
        include: newsAndVideoInclude,
        fields: newsAndVideoFields,
        excluded_ids: excludedIds,
        topic_type: 'news',
        sort: '-published_at',
        visibility: 'main_page',
        limit,
      })
      .then(denormalizeData)
      .catch(() => consoleError('news block', []));
  },

  popular: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 9,
        include: filterRequiredParams([Card1], 'include'),
        fields: filterRequiredParams([Card1], 'fields'),
        sort: '-views_count',
        days_period: TODAY_PERIOD,
      })
      .then(denormalizeData)
      .catch(() => consoleError('popular block', []));
  },

  video: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 4,
        include: newsAndVideoInclude,
        fields: newsAndVideoFields,
        topic_type: 'video',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('video block', []));
  },

  experts: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 3,
        include: filterRequiredParams([CardExpert], 'include'),
        fields: filterRequiredParams([CardExpert], 'fields'),
        list: 'experts',
        sort: 'list',
      })
      .then(denormalizeData)
      .catch(() => consoleError('experts block', []));
  },

  recipes: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 1,
        include: mainAndRecipesInclude,
        fields: mainAndRecipesFields,
        list: 'recepie',
        sort: 'list',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('recipes block', []));
  },

  videorecipes: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 4,
        include: videorecipiesAndSalatyInclude,
        fields: videorecipiesAndSalatyFields,
        list: 'videorecepie',
        sort: 'list',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('videorecipie block', []));
  },

  salaty: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 4,
        include: videorecipiesAndSalatyInclude,
        fields: videorecipiesAndSalatyFields,
        tag: 'salaty',
      })
      .then(denormalizeData)
      .catch(() => consoleError('salaty block', []));
  },

  tests: ({ bebopApi, consoleError }) => {
    return bebopApi
      .getTopics({
        limit: 4,
        include: filterRequiredParams([Card4, Card5], 'include'),
        fields: filterRequiredParams([Card4, Card5], 'fields'),
        rubric: 'test',
        visibility: 'main_page',
      })
      .then(denormalizeData)
      .catch(() => consoleError('tests block', []));
  },
});

export default compose(mainTopicsProvider, dataProvider);
