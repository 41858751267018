import PropTypes from 'prop-types';

import { getRealType } from 'site/utils';

import TopicHeaderImage from './TopicHeaderImage';
import TopicHeaderGallery from './TopicHeaderGallery';
import TopicHeaderVideo from './TopicHeaderVideo';

const topicTypeMap = {
  news: TopicHeaderImage,
  article: TopicHeaderImage,
  gallery: TopicHeaderGallery,
  'news-gallery': TopicHeaderGallery,
  video: TopicHeaderVideo,
  horoscope: TopicHeaderImage,
};

export default function TopicHeaderMedia({ content }) {
  const HeaderContent = topicTypeMap[getRealType(content)] || TopicHeaderImage;

  return <HeaderContent content={content} />;
}

TopicHeaderMedia.propTypes = {
  content: PropTypes.object.isRequired,
};
