import { compose } from 'core/libs/recompose';

import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import ListingSpecNewsStyles from './natives/ListingSpecNews';
import ListingSpecRubricStyles from './natives/ListingSpecRubric';
import ListingSpecSearchStyles from './natives/ListingSpecSearch';
import ContentSpecStyle from './natives/ContentSpecNative';
/**
 * Как выяснилось в комментарии (https://goo.gl/qPm9r4) у мобильных баннерах
 * нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const MobileAd = bindProps({
  siteId: '459070184',
  height: 250,
})(Ad);

const ListingSpecProps = bindProps({
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '459082888',
  options: {
    'p1': 'bxrzs',
    'p2': 'fqbd',
    'pct': 'a',
  },
});

export const Fullscreen = bindProps({
  name: 'Fullscreen',
  blockId: '459083052',
  height: null,
  options: {
    p1: 'btrbt',
    p2: 'etsa',
    pct: 'a',
  },
})(MobileAd);

export const TopBanner = bindProps({
  name: 'TopBanner',
  blockId: '459080300',
  options: {
    'p1': 'btrbv',
    'p2': 'fexc',
    'pct': 'a',
  },
})(MobileAd);


export const Rich = bindProps({
  name: 'Rich',
  height: null,
  blockId: '459082098',
  options: {
    'p1': 'btrbr',
    'p2': 'ffhi',
    'pct': 'a',
  },
})(MobileAd);

export const Listing1 = bindProps({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '459082960',
  options: {
    'p1': 'bwuiu',
    'p2': 'emil',
    'pct': 'a',
  },
})(MobileAd);

export const Listing2 = bindProps({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '459082926',
  options: {
    p1: 'bwuiv',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const Listing3 = bindProps({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '459082900',
  options: {
    p1: 'bwuiw',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const Listing4 = bindProps({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '459082892',
  options: {
    p1: 'bwuix',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const ListingSpecNewsNative = compose(
  ListingSpecProps,
  ListingSpecNewsStyles,
)(MobileAd);

export const ListingSpecRubricNative = compose(
  ListingSpecProps,
  ListingSpecRubricStyles,
)(MobileAd);

export const ListingSpecSearchNative = compose(
  ListingSpecProps,
  ListingSpecSearchStyles,
)(MobileAd);

export const Footer = bindProps({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '459083058',
  options: {
    'p1': 'bwuit',
    'p2': 'emil',
    'pct': 'b',
  },
})(MobileAd);

export const Content1 = bindProps({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '459083212',
  options: {
    'p1': 'bwuip',
    'p2': 'emil',
    'pct': 'a',
  },
})(MobileAd);

export const Content2 = bindProps({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '459083206',
  options: {
    p1: 'bwuiq',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const Content3 = bindProps({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '459083204',
  options: {
    p1: 'bwuir',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const Content4 = bindProps({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '459083200',
  options: {
    p1: 'bwuis',
    p2: 'emil',
    pct: 'a',
  },
})(MobileAd);

export const ContentSpec = compose(
  bindProps({
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '459083198',
    options: {
      'p1': 'bxrzv',
      'p2': 'fqbd',
      'pct': 'a',
    },
  }),
  ContentSpecStyle,
)(MobileAd);

export const InPage = bindProps({
  name: 'InPage',
  lazy: true,
  height: 200,
  blockId: '459082968',
  options: {
    'p1': 'btrbx',
    'p2': 'fexd',
    'pct': 'a',
  },
})(MobileAd);

export const Sponsored = compose(
  bindProps({
    name: 'Sponsored (COM)',
    height: null,
    blockId: '459082052',
    options: {
      'p1': 'bwujz',
      'p2': 'fooq',
      'pct': 'c',
    },
  }),
  sponsoredStyles,
)(MobileAd);

export const InGallery = bindProps({
  name: 'InGallery',
  blockId: '579389978',
  disableAutoInit: true,
  height: null,
})(MobileAd);
