import cx from 'classnames';
import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import styles from './index.styl';

export default function ListingSpecNews(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['listing-spec-news'], 'adv-native listing-spec-news')}>
      <GlobalStyles {...props} />
      <Component {...props} />
      <style jsx global>{`
        .listing-spec-news
          .ad_native_title
            font 16px/1.25 ${props.theme.fonts.nuance}
            color ${props.theme.colors.primary}
          .ad_label__text
            font 12px/14px ${props.theme.fonts.nuance}
            color ${props.theme.colors.hint}
      `}
      </style>
    </div>
  ));
}
