import Button from 'core/components/Button';

import styles from './index.styl';

/**
 * Кнопка с минимальной шириной 180 px
 */
export default function Button180(props) {
  const {
    children,
    ...otherProps
  } = props;
  return (
    <div className={styles.button}>
      <Button size='medium' {...otherProps}>{children}</Button>
    </div>
  );
}
