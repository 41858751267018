import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Mjolnir from 'core/components/Mjolnir';

import BackTitle from 'site/components/BackTitle';

import CardExpert, { CardExpertType1, CardExpertType2 } from 'site/cards/CardExpert';


function Experts({ content, isMobile }) {
  return (
    <BackTitle title='Эксперты' indentBottom={isMobile ? -5 : 0}>
      <div style={{ background: 'white' }}>
        <Mjolnir
          content={content}
          cardsContainerPadding={20}
          heroCard={isMobile ? CardExpertType2 : CardExpertType1}
          card={CardExpert}
          interitemSpacing={20}
        />
      </div>
    </BackTitle>
  );
}

Experts.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Experts);
