import cx from 'classnames';
import withTheme from 'core/components/theme';

import styles from './index.styl';

export default function RubricPageNative2Styles(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['native-rubric'], 'native-rubric')}>
      <Component {...props} />
      <style jsx global>{`
        .native-rubric
          .ad_native_wrapper
            & > a
              &:hover
                .ad_native_desc
                .ad_native_title
                  color ${props.theme.colors.active1}

          .ad_native_img
            background-color ${props.theme.colors.card}

          .ad_label__text
            font 16px/20px ${props.theme.fonts.nuance}
            color ${props.theme.colors.content}
            background-color ${props.theme.colors.active1}

          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.display}
            color ${props.theme.colors.primary}
            transition color ${props.theme.animations.hover}

          .ad_native_desc
            font 16px/1.2 ${props.theme.fonts.display}
            color ${props.theme.colors.primary}
            transition color ${props.theme.animations.hover}
        `}
      </style>
    </div>
  ));
}
