import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.styl';

const GlobalStyles = (props) => {
  const { theme } = props;

  return (
    <div className={cx(styles['adv-native'], 'adv-native')}>
      <style global jsx>{`
        .adv-native
          a:hover
            .ad_native_title
              color ${theme.colors.active1}

          .ad_native_title
            transition color ${theme.animations.hover}

          .ad_native_img
            background-color ${theme.colors.card}
      `}</style>
    </div>
  );
};

GlobalStyles.propTypes = {
  theme: PropTypes.object,
};

export default GlobalStyles;
