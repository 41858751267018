import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import { getBlockTitle } from 'site/utils';

import styles from './index.styl';

function BackTitle(props) {
  const {
    title,
    indentBottom,
    children,
    theme,
  } = props;

  const [styledTitle, setStyledTitle] = useState(title);
  useEffect(() => {
    // Делаем заголовки веселее только на клиенте, т.к. использование random
    // в getBlockTitle приводит к ошибкам мисматча серверной и клиентской разметки
    setStyledTitle(getBlockTitle(title));
  }, [title]);

  return (
    <Fragment>
      <style jsx>{`
        .${styles.title}
          color ${theme.colors.black}
          margin-bottom ${indentBottom || 0}px
      `}
      </style>
      <div className={styles.title}>{styledTitle}</div>
      {children}
    </Fragment>
  );
}

BackTitle.propTypes = {
  /** Текст заголовка */
  title: PropTypes.string.isRequired,
  /** Нижний отступ от текста */
  indentBottom: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
};

const BackTitleWithHOCs = withTheme(BackTitle);
BackTitleWithHOCs.displayName = 'BackTitle';

export default BackTitleWithHOCs;

export { BackTitle as StorybookComponent };
