export default {
  0: {
    imageSize: 50,
  },
  1: {
    imageSize: 120,
    showButton: true,
    showBody: true,
    showBottomLine: true,
  },
  2: {
    imageSize: 120,
    showButton: true,
    showBody: true,
  },
};
