import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import bindProps from 'core/components/bindProps';
import themeSwitcherHOC from 'core/components/themeSwitcher';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';
import Button from 'core/components/Button';
import AdvTooltip from 'core/components/AdvTooltip';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import cutDown from 'core/utils/cut-down';

import TitleCard from 'site/components/TitleCardM';

import { cardDateFormat } from 'site/utils';
import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'link',
  'topic_type',
  'published_at',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card5(props) {
  const {
    content,
    theme,
    isMobile,
    buttonTitle,
    type,
    location,
    position,
  } = props;

  const {
    headline,
    list_headline: listHeadline,
    link,
    topic_type: topicType,
    published_at: published,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    showButton,
    showTime,
    trimTitle,
    showIcon = true,
    imageWidth = 280,
    aspectRatio = 1,
    bottomMargin = 0,
  } = mapTypes[type] || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const isMainPage = location.pathname === '/';
  const Icon = mapIconByType[topicType];
  const shouldShowAdvTooltip = !!(isMainPage && position === 0 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;

  const iconSize = isMobile ? 20 : 30;

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          max-width ${imageWidth}px
          margin-bottom ${bottomMargin}px

        .${styles.time}
          font 400 14px/16px ${theme.fonts.display}

        .${styles.image}
          &:before
          &:after
            transition opacity ${theme.animations.hover}
      `}</style>
      <div className={styles.cardInner}>
        <Link
          type='secondary'
          to={topicLink}
          className={styles.link}
        >
          <div className={styles.image}>
            <Image
              src={cover}
              previewSrc={previewCover}
              maxWidth={imageWidth}
              aspectRatio={aspectRatio}
            />
          </div>
          <div className={styles.content}>
            {showIcon && Icon && (
              <div className={styles.icon}>
                <Icon width={iconSize} height={iconSize} />
              </div>
            )}
            <TitleCard fontWeight={700} disableColor>
              {trimTitle
                ? cutDown((listHeadline || headline), trimTitle, true)
                : (listHeadline || headline)
              }
            </TitleCard>
            {showTime && (
              <div className={styles.time}>
                {cardDateFormat(published)}
              </div>
            )}
            {showButton && (
              <div className={styles.button}>
                <Button>{buttonTitle}</Button>
              </div>
            )}
          </div>
        </Link>
      </div>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
        />
      )}
    </div>
  );
}

Card5.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3]),
  /** @ignore */
  breakpoint: PropTypes.string,
  /** Текст на кнопке (при наличии самой кнопки) */
  buttonTitle: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
};

Card5.defaultProps = {
  type: 0,
  buttonTitle: 'Пройти тест',
};

const Card = themeSwitcherHOC('dark')(cardHOC(withRouter(Card5)));

export const Card5Type1 = bindProps({ type: 1 })(Card);
export const Card5Type2 = bindProps({ type: 2 })(Card);
export const Card5Type3 = bindProps({ type: 3 })(Card);
export const Card5Type4 = bindProps({ type: 4 })(Card);

Card.displayName = 'Card5';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card5 as StorybookComponent };
