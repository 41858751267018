export default [
  '/people/zvezdy-adriana-lima',
  '/people/zvezdy-agness-deyn',
  '/people/zvezdy-alek-bolduin',
  '/people/zvezdy-aleksandr-buynov',
  '/people/zvezdy-aleksandr-cekalo',
  '/people/zvezdy-aleksandr-domogarov',
  '/people/zvezdy-aleksandr-rozenbaum',
  '/people/zvezdy-aleksandr-serov',
  '/people/zvezdy-aleksandr-zhulin',
  '/people/zvezdy-aleksey-panin',
  '/people/zvezdy-aleksey-yagudin',
  '/people/zvezdy-alena-hmelnickaya',
  '/people/zvezdy-alena-vodonaeva',
  '/people/zvezdy-alen-delon',
  '/people/zvezdy-alina-kabaeva',
  '/people/zvezdy-alisha-kiz',
  '/people/zvezdy-alla-dovlatova',
  '/people/zvezdy-alla-pugacheva',
  '/people/zvezdy-al-pachino',
  '/people/zvezdy-alsu',
  '/people/zvezdy-anastasiya-makeeva',
  '/people/zvezdy-anastasiya-stockaya',
  '/people/zvezdy-anastasiya-volochkova',
  '/people/zvezdy-anastasiya-zavorotnyuk',
  '/people/zvezdy-andrey-malahov',
  '/people/zvezdy-andzhelina-dzholi',
  '/people/zvezdy-ani-lorak',
  '/people/zvezdy-anna-kovalchuk',
  '/people/zvezdy-anna-kurnikova',
  '/people/zvezdy-anna-netrebko',
  '/people/zvezdy-anna-sedokova',
  '/people/zvezdy-anna-semenovich',
  '/people/zvezdy-antonio-banderas',
  '/people/zvezdy-arkadiy-arkanov',
  '/people/zvezdy-barak-obama',
  '/people/zvezdy-ben-afflek',
  '/people/zvezdy-bobbi-braun',
  '/people/zvezdy-boris-moiseev',
  '/people/zvezdy-bratya-zapashnye',
  '/people/zvezdy-bred-pitt',
  '/people/zvezdy-britni-spirs',
  '/people/zvezdy-dima-bilan',
  '/people/zvezdy-dita-fon-tiz',
  '/people/zvezdy-dmitriy-dibrov',
  '/people/zvezdy-dmitriy-nagiev',
  '/people/zvezdy-dryu-berrimor',
  '/people/zvezdy-dryu-berrimor',
  '/people/zvezdy-dzhanet-dzhekson',
  '/people/zvezdy-dzhek-nikolson',
  '/people/zvezdy-dzhennifer-eniston',
  '/people/zvezdy-dzhennifer-garner',
  '/people/zvezdy-dzhennifer-lopes',
  '/people/zvezdy-dzherri-holl',
  '/people/zvezdy-dzhessika-alba',
  '/people/zvezdy-dzhessika-simpson',
  '/people/zvezdy-dzheyn-fonda',
  '/people/zvezdy-dzhillian-anderson',
  '/people/zvezdy-dzhim-kerri',
  '/people/zvezdy-dzhoan-rouling',
  '/people/zvezdy-dzhonni-depp',
  '/people/zvezdy-dzhon-travolta',
  '/people/zvezdy-dzhon-travolta',
  '/people/zvezdy-dzhordzh-kluni',
  '/people/zvezdy-dzhovanna-antonelli',
  '/people/zvezdy-dzhud-lou',
  '/people/zvezdy-dzhulianna-mur',
  '/people/zvezdy-dzhuliya-roberts',
  '/people/zvezdy-edita-peha',
  '/people/zvezdy-edvard-norton',
  '/people/zvezdy-egor-beroev',
  '/people/zvezdy-ekaterina-guseva',
  '/people/zvezdy-eldar-ryazanov',
  '/people/zvezdy-elena-berezhnaya',
  '/people/zvezdy-elena-isinbaeva',
  '/people/zvezdy-elena-korikova',
  '/people/zvezdy-elena-vorobey',
  '/people/zvezdy-elena-zaharova',
  '/people/zvezdy-elizabet-teylor',
  '/people/zvezdy-elizaveta-boyarskaya',
  '/people/zvezdy-elton-dzhon',
  '/people/zvezdy-emi-uaynhaus',
  '/people/zvezdy-emma-uotson',
  '/people/zvezdy-enn-heteuey',
  '/people/zvezdy-enrike-iglesias',
  '/people/zvezdy-eva-gercigova',
  '/people/zvezdy-eva-longoriya',
  '/people/zvezdy-eva-mendes',
  '/people/zvezdy-eva-polna',
  '/people/zvezdy-evgeniy-plyushchenko',
  '/people/zvezdy-filipp-kirkorov',
  '/people/zvezdy-gay-richi',
  '/people/zvezdy-gosha-kucenko',
  '/people/zvezdy-grigoriy-leps',
  '/people/zvezdy-gven-stefani',
  '/people/zvezdy-gvinet-peltrou',
  '/people/zvezdy-harrison-ford',
  '/people/zvezdy-hayden-panetteri',
  '/people/zvezdy-haydi-klum',
  '/people/zvezdy-haydi-klum',
  '/people/zvezdy-helena-bonem-karter',
  '/people/zvezdy-hilari-daff',
  '/people/zvezdy-hillari-klinton',
  '/people/zvezdy-holli-berri',
  '/people/zvezdy-hyu-dzhekman',
  '/people/zvezdy-hyu-grant',
  '/people/zvezdy-igor-krutoy',
  '/people/zvezdy-igor-nikolaev',
  '/people/zvezdy-igor-petrenko',
  '/people/zvezdy-igor-vernik',
  '/people/zvezdy-ilya-averbuh',
  '/people/zvezdy-ingeborga-dapkunayte',
  '/people/zvezdy-inna-churikova',
  '/people/zvezdy-iosif-kobzon',
  '/people/zvezdy-irakliy-pirchalava',
  '/people/zvezdy-irina-alferova',
  '/people/zvezdy-irina-allegrova',
  '/people/zvezdy-irina-dubcova',
  '/people/zvezdy-irina-saltykova',
  '/people/zvezdy-irina-sluckaya',
  '/people/zvezdy-ivanka-tramp',
  '/people/zvezdy-ivan-urgant',
  '/people/zvezdy-kameron-diaz',
  '/people/zvezdy-karolina-kurkova',
  '/people/zvezdy-katya-lel',
  '/people/zvezdy-kayli-minoug',
  '/people/zvezdy-kelli-osborn',
  '/people/zvezdy-keti-holms',
  '/people/zvezdy-keti-perri',
  '/people/zvezdy-keti-prays',
  '/people/zvezdy-ketrin-zeta-dzhons',
  '/people/zvezdy-keyt-blanshett',
  '/people/zvezdy-keyt-hadson',
  '/people/zvezdy-keyt-middlton',
  '/people/zvezdy-keyt-moss',
  '/people/zvezdy-keyt-uinslet',
  '/people/zvezdy-kianu-rivz',
  '/people/zvezdy-kim-ketroll',
  '/people/zvezdy-kino',
  '/people/zvezdy-kira-naytli',
  '/people/zvezdy-kirsten-danst',
  '/people/zvezdy-kolin-farrell',
  '/people/zvezdy-konstantin-habenskiy',
  '/people/zvezdy-kortni-koks',
  '/people/zvezdy-kortni-lav',
  '/people/zvezdy-krishtianu-ronaldu',
  '/people/zvezdy-kristen-styuart',
  '/people/zvezdy-kristina-agilera',
  '/people/zvezdy-kristina-orbakayte',
  '/people/zvezdy-kseniya-novikova',
  '/people/zvezdy-kseniya-sobchak',
  '/people/zvezdy-lada-dens',
  '/people/zvezdy-larisa-dolina',
  '/people/zvezdy-larisa-verbickaya',
  '/people/zvezdy-layma-vaykule',
  '/people/zvezdy-ledi-gaga',
  '/people/zvezdy-leonardo-di-kaprio',
  '/people/zvezdy-leonid-agutin',
  '/people/zvezdy-leonid-yakubovich',
  '/people/zvezdy-leticiya-kasta',
  '/people/zvezdy-lindsi-lohan',
  '/people/zvezdy-lolita-milyavskaya',
  '/people/zvezdy-lyudmila-gurchenko',
  '/people/zvezdy-lyudmila-zykina',
  '/people/zvezdy-madonna',
  '/people/zvezdy-maksim',
  '/people/zvezdy-maksim-galkin',
  '/people/zvezdy-marat-safin',
  '/people/zvezdy-marina-aleksandrova',
  '/people/zvezdy-marina-anisina',
  '/people/zvezdy-mariya-poroshina',
  '/people/zvezdy-mariya-sharapova',
  '/people/zvezdy-mariya-sittel',
  '/people/zvezdy-masha-malinovskaya',
  '/people/zvezdy-masha-rasputina',
  '/people/zvezdy-maykl-duglas',
  '/people/zvezdy-mayk-tayson',
  '/people/zvezdy-mayli-sayrus',
  '/people/zvezdy-megan-foks',
  '/people/zvezdy-meg-rayan',
  '/people/zvezdy-melani-griffit',
  '/people/zvezdy-mel-gibson',
  '/people/zvezdy-merayya-keri',
  '/people/zvezdy-merilin-menson',
  '/people/zvezdy-merilin-monro',
  '/people/zvezdy-mettyu-makkonahi',
  '/people/zvezdy-mihail-efremov',
  '/people/zvezdy-mihail-porechenkov',
  '/people/zvezdy-mihail-shufutinskiy',
  '/people/zvezdy-mihail-zadornov',
  '/people/zvezdy-mikki-rurk',
  '/people/zvezdy-milla-yovovich',
  '/people/zvezdy-miranda-kerr',
  '/people/zvezdy-mira-sorvino',
  '/people/zvezdy-mishel-obama',
  '/people/zvezdy-monika-belluchchi',
  '/people/zvezdy-nadezhda-kadysheva',
  '/people/zvezdy-naomi-kempbell',
  '/people/zvezdy-natali-portman',
  '/people/zvezdy-natalya-andreychenko',
  '/people/zvezdy-natalya-podolskaya',
  '/people/zvezdy-natalya-vodyanova',
  '/people/zvezdy-natasha-koroleva',
  '/people/zvezdy-nelli-uvarova',
  '/people/zvezdy-nikita-dzhigurda',
  '/people/zvezdy-nikolay-baskov',
  '/people/zvezdy-nikol-kidman',
  '/people/zvezdy-nikol-richi',
  '/people/zvezdy-nikolya-sarkozi',
  '/people/zvezdy-oksana-fedorova',
  '/people/zvezdy-oleg-menshikov',
  '/people/zvezdy-olesya-zheleznyak',
  '/people/zvezdy-olga-drozdova',
  '/people/zvezdy-olga-kurilenko',
  '/people/zvezdy-olga-orlova',
  '/people/zvezdy-orlando-blum',
  '/people/zvezdy-oskar-kuchera',
  '/people/zvezdy-otar-kushanashvili',
  '/people/zvezdy-pamela-anderson',
  '/people/zvezdy-patrisiya-kaas',
  '/people/zvezdy-princessa-diana',
  '/people/zvezdy-princ-garri',
  '/people/zvezdy-princ-uilyam',
  '/people/zvezdy-rene-zelveger',
  '/people/zvezdy-rianna',
  '/people/zvezdy-riki-martin',
  '/people/zvezdy-riz-uizerspun',
  '/people/zvezdy-robbi-uilyams',
  '/people/zvezdy-robert-de-niro',
  '/people/zvezdy-robert-pattinson',
  '/people/zvezdy-robin-uilyams',
  '/people/zvezdy-rodion-gazmanov',
  '/people/zvezdy-roman-kostomarov',
  '/people/zvezdy-roza-syabitova',
  '/people/zvezdy-rumer-uillis',
  '/people/zvezdy-salma-hayek',
  '/people/zvezdy-sara-dzhessika-parker',
  '/people/zvezdy-sati-kazanova',
  '/people/zvezdy-selin-dion',
  '/people/zvezdy-sergey-bezrukov',
  '/people/zvezdy-sergey-lazarev',
  '/people/zvezdy-sergey-zhigunov',
  '/people/zvezdy-sergey-zhukov',
  '/people/zvezdy-sergey-zverev',
  '/people/zvezdy-sharliz-teron',
  '/people/zvezdy-sher',
  '/people/zvezdy-sheron-stoun',
  '/people/zvezdy-shon-penn',
  '/people/zvezdy-shura',
  '/people/zvezdy-sienna-miller',
  '/people/zvezdy-silvestr-stallone',
  '/people/zvezdy-sindi-krouford',
  '/people/zvezdy-skarlett-yohansson',
  '/people/zvezdy-sofi-loren',
  '/people/zvezdy-sofiya-rotaru',
  '/people/zvezdy-sogdiana',
  '/people/zvezdy-tatyana-lazareva',
  '/people/zvezdy-tatyana-navka',
  '/people/zvezdy-tatyana-vedeneeva',
  '/people/zvezdy-teylor-lotner',
  '/people/zvezdy-tina-kandelaki',
  '/people/zvezdy-tina-terner',
  '/people/zvezdy-tom-henks',
  '/people/zvezdy-tom-kruz',
  '/people/zvezdy-tori-spelling',
  '/people/zvezdy-tutta-larsen',
  '/people/zvezdy-uesli-snayps',
  '/people/zvezdy-uill-smit',
  '/people/zvezdy-uitni-hyuston',
  '/people/zvezdy-uma-turman',
  '/people/zvezdy-vadim-galygin',
  '/people/zvezdy-valdis-pelsh',
  '/people/zvezdy-valentin-yudashkin',
  '/people/zvezdy-valeriya',
  '/people/zvezdy-valeriy-leontev',
  '/people/zvezdy-valeriy-meladze',
  '/people/zvezdy-varvara',
  '/people/zvezdy-vera-brezhneva',
  '/people/zvezdy-viktor-drobysh',
  '/people/zvezdy-viktoriya-bekhem',
  '/people/zvezdy-viktoriya-dayneko',
  '/people/zvezdy-vitas',
  '/people/zvezdy-vladimir-klichko',
  '/people/zvezdy-vladimir-presnyakov',
  '/people/zvezdy-vladimir-putin',
  '/people/zvezdy-yana-churikova',
  '/people/zvezdy-yana-rudkovskaya',
  '/people/zvezdy-yuliya-kovalchuk',
  '/people/zvezdy-yulya-volkova',
  '/people/zvezdy-yuriy-yakovlev',
  '/people/zvezdy-zak-efron',
  '/people/zvezdy-zemfira',
  '/people/zvezdy-zhan-klod-van-damm',
  '/people/zvezdy-zhanna-friske',
  '/people/zvezdy-zherar-deparde',
  '/people/zvezdy-zhizel-byundhen',
];
