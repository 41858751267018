import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Bouesque from 'core/components/Bouesque';
import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import YandexMetrikaGoalSender from 'core/components/MetricsEventSender/YandexMetrikaGoalSender';
import SelfPromo from 'core/components/SelfPromo';

import Prediction from 'site/components/Prediction';
import Divider from 'site/components/Divider';
import { VERTICAL, VERTICAL_MOBILE } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';
import { Smi2Vertical } from 'site/components/Partners';

import {
  AdPlace2,
  AdPlace3,
  Parallax,
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  Listing2,
  Listing3,
} from 'site/components/Ads/mobile';

import Main from 'site/components/Main';
import NewsAndPopular from 'site/components/NewsAndPopular';
import Video from 'site/components/Video';
import Experts from 'site/components/Experts';
import Recipes from 'site/components/Recipes';
import Tests from 'site/components/Tests';
import CompatibilityWidget from 'site/components/CompatibilityWidget';

import { MAIN_BLOCK_LIMIT } from 'site/constants';


function MainPageContent(props) {
  const {
    mainTopics,
    news,
    popular,
    video,
    experts,
    recipes,
    videorecipes,
    salaty,
    tests,
    isMobile,
  } = props;

  const indent = isMobile ? VERTICAL_MOBILE : VERTICAL;
  const sideIndent = isMobile ? 20 : 0;

  const mainNewsLimit = MAIN_BLOCK_LIMIT - mainTopics.length;
  const mainNews = news.slice(0, mainNewsLimit);
  const newsFeed = news.slice(mainNewsLimit);

  return (
    <AdWrapper bottom={indent}>
      <ColumnLayout
        rightColumn={(
          <Fragment>
            <Bouesque
              height='42px'
              radius='6px'
              withoutIcon
            />
            <IndentWrap bottom={indent} />
            <AdPlace2 />
            <SelfPromo />
            <IndentWrap bottom={indent} />
            <Smi2Vertical />
            <IndentWrap bottom={indent} />
            <AdPlace3 />
          </Fragment>
        )}
      >
        <IndentWrap
          bottom={isMobile ? 30 : VERTICAL}
          left={sideIndent}
          right={sideIndent}
        >
          <Main content={mainTopics} news={mainNews} />
        </IndentWrap>
        <IndentWrap
          bottom={indent}
          left={sideIndent}
          right={sideIndent}
        >
          <Prediction />
        </IndentWrap>
        <Desktop><SuperFooter /></Desktop>
        <Mobile><Listing1 /></Mobile>
        <IndentWrap bottom={isMobile ? 0 : VERTICAL}>
          <NewsAndPopular news={newsFeed} popular={popular} />
        </IndentWrap>
      </ColumnLayout>

      <Desktop><Parallax /></Desktop>

      <ColumnLayout
        rightColumn={(
          <Fragment>
            <YandexMetrikaGoalSender click='name_compatibility_click' show='name_compatibility_show'>
              <CompatibilityWidget side />
            </YandexMetrikaGoalSender>
            <IndentWrap bottom={indent} />
          </Fragment>
        )}
      >
        <Mobile>
          <Divider top={10} bottom={VERTICAL_MOBILE} />
        </Mobile>
        <IndentWrap bottom={isMobile ? 0 : VERTICAL + 20}>
          <Video content={video} />
        </IndentWrap>
        <Mobile>
          <Divider top={10} bottom={VERTICAL_MOBILE} />
        </Mobile>
        <IndentWrap bottom={indent}>
          <Experts content={experts} />
          <Mobile>
            <Divider />
          </Mobile>
        </IndentWrap>
        <BreakpointSwitcher
          desktop={<Context />}
          mobile={<Listing2 />}
        />
        <IndentWrap bottom={isMobile ? 30 : VERTICAL}>
          <Recipes
            recipes={recipes}
            videorecipes={videorecipes}
            salaty={salaty}
          />
        </IndentWrap>
        <IndentWrap bottom={indent}>
          <Tests content={tests} />
        </IndentWrap>
        <BreakpointSwitcher
          desktop={<Context count={2} />}
          mobile={<Listing3 />}
        />
        <Mobile>
          <Divider top={10} bottom={10} />
        </Mobile>
      </ColumnLayout>
    </AdWrapper>
  );
}

MainPageContent.propTypes = {
  mainTopics: PropTypes.array,
  news: PropTypes.array,
  popular: PropTypes.array,
  video: PropTypes.array,
  experts: PropTypes.array,
  recipes: PropTypes.array,
  videorecipes: PropTypes.array,
  salaty: PropTypes.array,
  tests: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPageContent);
