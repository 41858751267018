import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import skip from 'core/resolver/skip';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Lightning from 'core/components/GameCompare/Lightning';

import styles from './index.styl';


const requiredPayloadFields = [
  'link',
  'headline',
  'listHeadline',
];

const requiredPayloadImports = [
  'content',
];
const relationships = resolveRelationships(requiredPayloadImports, {}, {
  content: {},
});


function CompareCard(props) {
  const {
    content,
    theme: {
      fonts,
      colors,
    },
    isMobile,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    listHeadline,
  } = content.attributes;

  const {
    content: {
      widgets,
    },
  } = relationships(content);

  if (!widgets || widgets?.length === 0) return null;

  const {
    first_announce: firstAnnounce,
  } = widgets[0]?.attributes || {};

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.wrapper}
          background-color ${colors.layout}

        .headline
          font 700 22px/27px ${fonts.display}
          color ${colors.primary}
          .${styles.card}:hover &
            color ${colors.active1}
          :global(.mobile) &
            font 700 20px/25px ${fonts.display}
  
        .${styles.firstAnnounce}
          font 500 16px/20px ${fonts.text}
      `}</style>
      <Link
        className={styles.wrapper}
        to={link}
        type='secondary'
      >
        <Lightning
          imageMaxWidth={isMobile ? 550 : 360}
          height={228}
          widget={widgets[0]}
          progressType={4}
          progressPosition={isMobile ? 0 : 20}
          lightningColor={colors.black}
          borderRadius='6px'
        />
        <div className={styles.body}>
          {(listHeadline || headline) && (
            <div className='headline'>
              {listHeadline || headline}
            </div>
          )}
          {firstAnnounce && <div className={styles.firstAnnounce}>{firstAnnounce}</div>}
        </div>
      </Link>
    </div>
  );
}

CompareCard.propTypes = {
  /** Контент топика */
  content: modelPropTypes(topicAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const Card = skip(withTheme(withBreakpoint(CompareCard)));

Card.displayName = 'CompareCard';
Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;

export { CompareCard as StorybookComponent };
