import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Mjolnir from 'core/components/Mjolnir';

import BackTitle from 'site/components/BackTitle';

import Card3 from 'site/cards/Card3';
import Card4, { Card4Type3 } from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';


function Video({ content, isMobile }) {
  return (
    <BackTitle indentBottom={isMobile ? -20 : -10} title='Видео'>
      <Mjolnir
        content={content}
        heroCard={isMobile ? Card4Type3 : Card4}
        card={isMobile ? Card3 : Card5}
        interitemSpacing={isMobile ? 20 : 10}
        cardsContainerPadding={isMobile ? 20 : 0}
      />
    </BackTitle>
  );
}

Video.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Video);
