import PropTypes from 'prop-types';

import modelPropTypes, {
  rubricAttributes,
} from 'core/utils/prop-types/model';

import BreadcrumbsCore from 'core/components/Breadcrumbs';
import withTheme from 'core/components/theme';


function Breadcrumbs(props) {
  const {
    content,
    theme,
  } = props;

  const { data } = content;

  const {
    attributes: {
      root_slug: rootSlug,
      slug,
    },
  } = data;

  return (
    <div className='breadcrumbs'>
      <style jsx>{`
        .breadcrumbs
          :global(li) 
            font italic 16px/1.25 ${theme.fonts.display}
            color #bbb
          :global(a) 
            font italic 16px/1.25 ${theme.fonts.display}
            color #bbb
            &:visited
              color #bbb
            &:hover
              color #bbb
              border-bottom 1px solid ${theme.colors.active1}
      `}
      </style>
      <BreadcrumbsCore
        root_slug={rootSlug}
        rubric_slug={slug || rootSlug}
      />
    </div>
  );
}

Breadcrumbs.propTypes = {
  content: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
  }),
  theme: PropTypes.object,
};

export default withTheme(Breadcrumbs);
