import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { compose } from 'core/libs/recompose';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import modelPropTypes, {
  rubricAttributes,
  ancestryAttributes,
} from 'core/utils/prop-types/model';

import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Page from 'core/components/Page';

import resolve from 'core/resolver/resolve';

import RubricHeader from 'site/components/RubricHeader';
import BasePage from 'site/components/BasePage';

import Card1 from 'site/cards/Card1';

import { getGroups } from 'site/utils';

import styles from './index.styl';


const LIMIT = 100;

function Data(props) {
  const {
    rubrics,
    allTopics,
    breakpoint,
  } = props;

  const {
    data: {
      attributes: {
        title,
        meta_title: metaTitle,
        meta_description: metaDescription,
      },
    },
  } = rubrics;

  const groups = useMemo(() => getGroups(rubrics, allTopics), [rubrics, allTopics]);

  return (
    <BasePage>
      <Page
        title={metaTitle || title}
        description={metaDescription}
      >
        <RubricHeader rawRubric={rubrics} title={title} />
        <div className={cx(styles.groups, styles[breakpoint])}>
          {groups.map(group => {
            const {
              id,
              attributes: {
                title: letter,
                slug,
                root_slug: rootSlug,
              },
              topics,
            } = group;
            return (
              <div className={styles.group} key={id}>
                <div className={styles.letter}>
                  <Link to={`/${rootSlug}/${slug}`} type='secondary'>
                    {letter}
                  </Link>
                </div>
                <div className={styles.groupList}>
                  <Feed
                    content={topics}
                    card={Card1}
                    interitemSpacing={10}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Page>
    </BasePage>
  );
}

Data.propTypes = {
  allTopics: PropTypes.array,
  breakpoint: breakpointPropTypes(),
  rubrics: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
    meta: PropTypes.shape({
      ancestry: ancestryAttributes,
    }),
  }).isRequired,
  location: PropTypes.object,
};

const dataProvider = resolve({
  firstTopics(props) {
    const {
      bebopApi,
      firstTopics,
      location: { pathname },
    } = props;

    return firstTopics || bebopApi
      .getTopics({
        ...getParams(pathname),
        with_filtered_count: 1,
      });
  },

  rubrics(props) {
    const {
      bebopApi,
      rubrics,
      location: { pathname },
    } = props;

    const [rubricRoot, rubric] = pathname.split('/').filter(Boolean);

    return rubrics || bebopApi
      .getRubric({
        rubric_slug: rubric,
        root_slug: rubricRoot,
      });
  },
});

const additionalProvider = resolve({
  allTopics(props) {
    const {
      firstTopics,
      bebopApi,
      location: { pathname },
      allTopics,
    } = props;

    const topicsCount = firstTopics.meta.filtered_count;

    const iterations = Math.ceil(topicsCount / LIMIT);

    const payloads = [];

    for (let i = 1; i < iterations; i++) {
      payloads.push(
        bebopApi
          .getTopics({
            ...getParams(pathname),
            offset: i * LIMIT,
          })
      );
    }

    return allTopics || Promise.all(payloads)
      .then(data => {
        return data.reduce((stack, item) => {
          return {
            data: stack.data.concat(item.data),
            included: stack.included.concat(item.included),
          };
        }, firstTopics);
      })
      .then(denormalizeData);
  },
});

function getParams(pathname) {
  const [rubricRoot, rubric] = pathname.split('/').filter(Boolean);

  return {
    rubric,
    rubric_root: rubricRoot,
    include: 'rubric',
    fields: filterRequiredParams([Card1], 'fields'),
  };
}

const composedProvider = compose(dataProvider, additionalProvider);

export default withPageHocs(composedProvider)(withBreakpoint(Data));
