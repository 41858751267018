import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ColumnLayout from 'core/components/ColumnLayout';

import InnerSideColumn from 'site/components/InnerSideColumn';

import AdFooter from 'site/components/Ads/AdFooter';

export default function BasePage({
  isCompatibilityPage,
  showCompatibility,
  children,
}) {
  return (
    <Fragment>
      <ColumnLayout
        rightColumn={(
          <InnerSideColumn
            isCompatibilityPage={isCompatibilityPage}
            showCompatibility={showCompatibility}
          />
        )}
      >
        {children}
      </ColumnLayout>
      <AdFooter />
    </Fragment>
  );
}

BasePage.propTypes = {
  isCompatibilityPage: PropTypes.bool,
  showCompatibility: PropTypes.bool,
};
