import cx from 'classnames';
import withTheme from 'core/components/theme';

import styles from './index.styl';

export default function Native1(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['ad-native-1'], 'ad-native-1')}>
      <Component {...props} />
      <style jsx global>{`
        .ad-native-1
          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.display}

          .ad_native_img
            background-color ${props.theme.colors.card}
            &:before
              transition opacity ${props.theme.animations.hover}

          .ad_label__text
            font 14px/16px ${props.theme.fonts.text}
            background-color ${props.theme.colors.active1}

          .adv_info__text
            font 14px/1.1 ${props.theme.fonts.nuance}

          img
            background-color ${props.theme.colors.primary}
      `}
      </style>
    </div>
  ));
}
