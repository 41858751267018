import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  rawRubricFetcher,
  rawTopicsFetcher,
} from 'core/fetchers/rubric';

import withPageHocs from 'core/components/withPageHocs';
import Rubric from 'core/components/Rubric';

import RubricHeader from 'site/components/RubricHeader';
import BasePage from 'site/components/BasePage';

import Topics from './Topics';


function RubricPage({ rawRubric, rawTopics }) {
  return (
    <BasePage>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        titleComponent={RubricHeader}
        limit={20}
      >
        {({ topics }) => <Topics topics={topics} />}
      </Rubric>
    </BasePage>
  );
}

RubricPage.propTypes = {
  rawRubric: PropTypes.object,
  rawTopics: PropTypes.object,
};

const dataProvider = resolve({
  rawRubric: rawRubricFetcher(),
  rawTopics: rawTopicsFetcher({
    include: Topics.include,
    fields: Topics.fields,
    limit: 20,
  }),
});

export default withPageHocs(dataProvider)(RubricPage);
