import PropTypes from 'prop-types';
import { Fragment } from 'react';

import dataProvider from 'core/resolver/data/topic';

import Bouesque from 'core/components/Bouesque';
import ColumnLayout from 'core/components/ColumnLayout';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import Page from 'core/components/Page';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';
import withPageHocs from 'core/components/withPageHocs';
import resolveRelationships from 'core/utils/relationships';
import SelfPromo from 'core/components/SelfPromo';

import {
  IndentWrap,
  PageWrapper,
} from 'site/components/Wrappers';
import TopicHeader from 'site/pages/topic/TopicHeader';
import { VERTICAL, VERTICAL_MOBILE } from 'site/components/Indents';

import {
  AdPlace2,
  AdPlace3,
} from 'site/components/Ads/desktop';
import { Smi2Vertical } from 'site/components/Partners';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard({ content: topic, isMobile, theme }) {
  const indent = isMobile ? VERTICAL_MOBILE : VERTICAL;
  return (
    <Page>
      <style jsx>{`
        .announce
          font 18px/27px ${theme.fonts.text}
          color ${theme.colors.primary}
          :global(.mobile) &
            padding-left 20px
            font 16px/22px ${theme.fonts.text}
      `}</style>
      <PageWrapper>
        <Topic content={topic}>
          {content => {
            const {
              content: {
                widgets,
              },
            } = relationships(content);

            if (widgets.length === 0) return null;

            const {
              final_announce: finalAnnounce,
            } = widgets[0].attributes;

            return (
              <ColumnLayout
                rightColumn={(
                  <Fragment>
                    <Bouesque
                      height='42px'
                      radius='6px'
                      withoutIcon
                    />
                    <IndentWrap bottom={indent} />
                    <AdPlace2 />
                    <SelfPromo />
                    <IndentWrap bottom={indent} />
                    <Smi2Vertical />
                    <IndentWrap bottom={indent} />
                    <AdPlace3 />
                  </Fragment>
                )}
              >
                <IndentWrap
                  top={20}
                  bottom={isMobile ? 80 : 50}
                  left={20}
                  ight={20}
                >
                  <TopicHeader content={content} isGameCompare />
                  <IndentWrap top={20} />
                  <div className='announce'>{finalAnnounce}</div>
                  <IndentWrap top={isMobile ? 35 : 25} />
                  <LeaderBoardCore content={content} widget={widgets[0]} />
                </IndentWrap>
              </ColumnLayout>
            );
          }}
        </Topic>
      </PageWrapper>
    </Page>
  );
}

LeaderBoard.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withPageHocs(dataProvider)(withBreakpoint(withTheme(LeaderBoard)));
