import PropTypes from 'prop-types';

import Socializator from 'core/components/Socializator';

const SocialShare = ({ url }) => {
  const socialProps = {
    vkontakte: {
      'utm': 'utm_source=vksharing&utm_medium=social',
    },
    odnoklassniki: {
      'utm': 'utm_source=oksharing&utm_medium=social',
    },
    'telegram': {
      'utm': 'utm_source=tgsharing&utm_medium=social',
    },
  };
  return (
    <Socializator url={url} {...socialProps} />
  );
};

SocialShare.propTypes = {
  url: PropTypes.string,
};

export default SocialShare;
