import PropTypes from 'prop-types';

export default function VerticalIndent({ indent }) {
  return <div style={{ marginTop: `${indent}px` }} />;
}

VerticalIndent.propTypes = {
  indent: PropTypes.number,
};

VerticalIndent.defaultProps = {
  indent: 40,
};
