import cx from 'classnames';
import withTheme from 'core/components/theme';

import styles from './index.styl';

export default function Native3(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['ad-native-3'], 'ad-native-3')}>
      <Component {...props} />
      <style jsx global>{`
        .ad-native-3
          .ad_native_wrapper
            background-color ${props.theme.colors.layout}
            > a
              &:hover
                .ad_native_title
                .ad_native_desc
                  color ${props.theme.colors.active1}
          .ad_native_content-wrapper
            background-color ${props.theme.colors.card}
          .ad_native_img
            background-color ${props.theme.colors.card}

          .ad_native_title
          .ad_native_desc
            color ${props.theme.colors.primary}

          .ad_native_desc
            font 14px/20px ${props.theme.fonts.nuance}

          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.display}

          .ad_label__text
            color ${props.theme.colors.hint}
            font 14px/1 ${props.theme.fonts.nuance}

          .adv_info__text
            font 14px/1.3 ${props.theme.fonts.nuance}
          img
            background-color ${props.theme.colors.active1}
      `}
      </style>
    </div>
  ));
}
