import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

function Hr(props) {
  const {
    indent,
    indentTop,
    indentBottom,
    theme,
  } = props;

  return (
    <div className='hr'>
      <style jsx>{`
        .hr
          margin-top ${(indentTop || indent) + 'px'}
          margin-bottom ${(indentBottom || indent) + 'px'}
          background ${theme.colors.grey2}
      `}
      </style>
      <style jsx>{`
        .hr
          height 1px
      `}
      </style>
    </div>
  );
}

Hr.propTypes = {
  /**
   * Отступ сверху и снизу.
   */
  indent: PropTypes.number,
  /**
   * Верхний отступ. Перезаписывает верхний indent
   */
  indentTop: PropTypes.number,
  /**
   * Нижний отступ. Перезаписывает нижний indent
   */
  indentBottom: PropTypes.number,

  /**
   * @ignore
   */
  theme: PropTypes.object,
};

Hr.defaultProps = {
  indent: 10,
};

const HrWithHOCs = withTheme(Hr);
HrWithHOCs.displayName = 'Hr';

export default HrWithHOCs;

export { Hr as StorybookComponent };
