import PropTypes from 'prop-types';

import Title2 from 'site/components/Title2';

import styles from './index.styl';

export default function WhiteBlock(props) {
  const {
    title,
    children,
  } = props;

  return (
    <div className={styles.block}>
      {title && (
        <div className={styles.title}>
          <Title2>{title}</Title2>
        </div>
      )}
      {children}
    </div>
  );
}

WhiteBlock.propTypes = {
  title: PropTypes.string,
};
