import PropTypes from 'prop-types';
import { incut as coreIncut } from 'core/components/TopicContent/blocks';

import styles from './index.styl';

export default function Incut(props) {
  const { block, parentProps, ctx } = props;

  const { theme } = parentProps;

  return (
    <div className={styles.incut}>
      <style jsx>{`
      .${styles.incut}
        &::before, &::after
          background-color ${theme.colors.active1}
    `}</style>
      {coreIncut(block, parentProps, ctx)}
    </div>
  );
}

Incut.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
