import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';

import Feed from 'core/components/Feed';
import Mjolnir from 'core/components/Mjolnir';
import Button from 'core/components/Button';
import Link from 'core/components/Link';

import Divider from 'site/components/Divider';
import BackTitle from 'site/components/BackTitle';
import { MainPageNative2 } from 'site/components/Ads/desktop';
import TabbedPopular from 'site/components/TabbedPopular';

import { Card3Type1, Card3Type8 } from 'site/cards/Card3';
import { Card5Type2, Card5Type4 } from 'site/cards/Card5';

import styles from './index.styl';


function NewsAndPopular({ news, popular, isDesktop }) {
  const NewsComponent = isDesktop ? Feed : Mjolnir;

  return (
    <BackTitle indentBottom={isDesktop ? 0 : -20} title='Новости'>
      <div className={styles.newsAndPopular}>
        <div className={styles.news}>
          <NewsComponent
            content={news}
            heroCard={isDesktop ? Card5Type2 : Card5Type4}
            card={isDesktop ? Card3Type1 : Card3Type8}
            interitemSpacing={20}
            cardsContainerPadding={20}
            cardsReplacement={{
              3: replaceCardWithAd(MainPageNative2, isDesktop),
            }}
          />
          <div className={styles.newsButton}>
            <Link to='/news' type='blank'>
              <Button size='small' >
                Все новости
              </Button>
            </Link>
          </div>
        </div>
        {!isDesktop && <Divider top={20} bottom={20} />}
        <div className={styles.popular}>
          <TabbedPopular content={popular} />
        </div>
      </div>
    </BackTitle>
  );
}

NewsAndPopular.propTypes = {
  news: PropTypes.array,
  popular: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsAndPopular);
