import Page from 'core/components/Page';
import Feed from 'core/components/Feed';

import BasePage from 'site/components/BasePage';
import RubricHeader from 'site/components/RubricHeader';
import { VerticalIndent } from 'site/components/Indents';

import { Card2Type1 } from 'site/cards/Card2';
import { Card4Type2 } from 'site/cards/Card4';
import Card5 from 'site/cards/Card5';

import data from './data';


const PAGE_TITLE = 'Спецпроекты';


export default function Special() {
  return (
    <Page
      title={PAGE_TITLE}
      description='Спецпроекты портала Страсти'
    >
      <BasePage>
        <RubricHeader title={PAGE_TITLE} />
        <Feed
          content={data.slice(0, 2)}
          card={Card4Type2}
          columns={2}
          grid
          interitemSpacing={40}
        />
        <VerticalIndent indent={20} />
        <Feed
          content={data.slice(2, 5)}
          card={Card5}
          columns={3}
          grid
          interitemSpacing={40}
        />
        <VerticalIndent indent={20} />
        <Feed
          content={data.slice(5, data.length)}
          card={Card2Type1}
          columns={3}
          grid
          interitemSpacing={40}
        />
      </BasePage>
    </Page>
  );
}
