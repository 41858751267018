import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import ProgressBar from 'site/components/ProgressBar';

import H6 from 'core/components/H6';

import styles from './index.styl';

const detailsArray = [
  {
    key: 'love',
    label: 'В любви',
  },
  {
    key: 'marriage',
    label: 'В браке',
  },
  {
    key: 'sex',
    label: 'В сексе',
  },
  {
    key: 'relationship',
    label: 'В отношениях',
  },
];

const CompatibilityResult = ({ theme, content, title }) => {
  const { body, overall } = content;
  return (
    <div className={styles.progressBarWrapper}>
      <style jsx>{`
        .${styles.progressBarWrapper}
          font-family ${theme.fonts.display}

        .${styles.itemLabel}
          font-family ${theme.fonts.nuance}
      `}</style>
      <h1 className={styles.header}>
        {title}
      </h1>
      <ProgressBar
        size={115}
        value={overall}
        className={styles.progressBar}
      />
      <H6 className={styles.resultBody}>
        {body}
      </H6>
      <div className={styles.detailedInfo}>
        {
          detailsArray.map(({ key, label }) => (
            <div
              key={key}
              className={styles.detailedItem}
            >
              <div className={styles.itemValue}>
                {content[key]}%
              </div>
              <div className={styles.itemLabel}>
                {label}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

CompatibilityResult.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({
    body: PropTypes.string.isRequired,
    overall: PropTypes.number.isRequired,
    love: PropTypes.number.isRequired,
    marriage: PropTypes.number.isRequired,
    sex: PropTypes.number.isRequired,
    relationship: PropTypes.number.isRequired,
  }).isRequired,
  theme: PropTypes.object,
};

export default withTheme(CompatibilityResult);
