import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Helmet } from 'core/libs/helmet';

import resolveRelationships from 'core/utils/relationships';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { SideIndent } from 'core/components/Wrappers';

import TopicsMeta from 'core/components/Topic/TopicsMeta';
import { VerticalIndent } from 'site/components/Indents';
import CompatibilityWidget from 'site/components/CompatibilityWidget';
import SocialShare from 'site/components/SocialShare';

import CompatibilityResult from './CompatibilityResult';
import TopicWrapper from './TopicWrapper';
import TopicContent from './TopicContent';
import TopicFooter from './TopicFooter';

import styles from './index.styl';

const relationships = resolveRelationships(['name_compatibility'], {});

const TopicCompatibility = ({ content, isMobile }) => {
  const { attributes: { headline } } = content;
  const { name_compatibility: compatibilityContent } = relationships(content);
  const Wrapper = isMobile ? SideIndent : Fragment;

  return (
    <TopicWrapper content={content}>
      <TopicsMeta content={content} />
      <Helmet>
        <title>{`Совместимость имен ${headline} в любви, браке, сексе, отношениях`}</title>
        <meta name='description' content={`${headline}: совместимость имен в любви и браке, сексе и отношениях. Узнайте все о совместимости партнеров по имени на Passion.`} />
      </Helmet>
      <CompatibilityResult content={compatibilityContent} title={headline} />
      <VerticalIndent indent = {isMobile ? 25 : 20} />
      <div className={styles.socialShareWrapper}>
        <SocialShare />
      </div>
      <VerticalIndent indent = {isMobile ? 25 : 30} />
      <Wrapper>
        <TopicContent content={content} />
      </Wrapper>
      <CompatibilityWidget />
      <Wrapper>
        <TopicFooter content={content} />
      </Wrapper>
    </TopicWrapper>
  );
};

TopicCompatibility.propTypes = {
  content: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withBreakpoint(TopicCompatibility);
