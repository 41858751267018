import PropTypes from 'prop-types';

import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile } from 'core/components/breakpoint';
import ColumnLayout from 'core/components/ColumnLayout';
import EmptyWrapper from 'core/components/EmptyWrapper';

import TopicHeaderPremium from 'site/pages/topic/TopicHeaderPremium';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { IndentWrap, TopicMaxWidth } from 'site/components/Wrappers';

import { TopBanner } from 'site/components/Ads/mobile';


export default function TopicWrapper(props) {
  const {
    showPartners,
    showCompatibility,
    content,
    children,
    infinityIndex,
  } = props;

  const {
    attributes: {
      is_premium: isPremium,
    },
  } = content;

  const Wrapper = isPremium ? EmptyWrapper : ColumnLayout;

  return (
    <IndentWrap bottom={20}>
      {infinityIndex > 0 && (
        <Mobile>
          <AdWrapper bottom={20}>
            <TopBanner lazy />
          </AdWrapper>
        </Mobile>
      )}
      {isPremium && <TopicHeaderPremium content={content} />}
      <Wrapper
        rightColumn={(
          <InnerSideColumn
            showSmi24Vertical={showPartners}
            showCompatibility={showCompatibility}
          />
        )}
      >
        <TopicMaxWidth>
          {children}
        </TopicMaxWidth>
      </Wrapper>
    </IndentWrap>
  );
}

TopicWrapper.propTypes = {
  showPartners: PropTypes.bool,
  showCompatibility: PropTypes.bool,
  content: PropTypes.object,
  infinityIndex: PropTypes.number,
};
