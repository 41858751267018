import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import skip from 'core/resolver/skip';

import { getFullLink } from 'core/utils/url-helper';

import Page from 'core/components/Page';
import Lead from 'core/components/Lead';
import Text from 'core/components/Text';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { VERTICAL, VERTICAL_MOBILE, VerticalIndent } from 'site/components/Indents';
import { IndentWrap } from 'site/components/Wrappers';
import BasePage from 'site/components/BasePage';
import SocialShare from 'site/components/SocialShare';
import Headline from 'site/components/Headline';
import CompatibilityWidget from 'site/components/CompatibilityWidget';

import { HOST } from 'site/shared-app-config';
import { SOVMESTIMOST_LINK, SOVMESTIMOST_TITLE, SOVMESTIMOST_DESCRIPTION } from 'site/constants';

import {
  femaleNames,
  maleNames,
} from 'site/components/CompatibilityWidget/data';
import NamesList from './NamesList';

const SITE_URL = 'https://' + HOST;

const Compatibility = ({ isMobile }) => {
  const spacing = isMobile ? 15 : VERTICAL;
  const sideSpacings = {
    right: isMobile ? spacing : 0,
    left: isMobile ? spacing : 0,
    bottom: isMobile ? VERTICAL_MOBILE : 0,
  };
  return (
    <Page
      title={SOVMESTIMOST_TITLE}
      description={SOVMESTIMOST_DESCRIPTION}
    >
      <Helmet>
        <meta property='og:image' content={getFullLink(require('./og-image.png'), SITE_URL)} />
        <meta property='vk:image' content={getFullLink(require('./vk-image.png'), SITE_URL)} />
      </Helmet>
      {/* Отключаем блоки в правой колонке на разводной странице совместимости имен */}
      <BasePage isCompatibilityPage>
        <IndentWrap
          top={spacing}
          {...sideSpacings}
        >
          <Headline>Совместимость имен</Headline>
          <SocialShare url={SOVMESTIMOST_LINK} />
          <VerticalIndent indent={30} />
          <Lead>
            Специалисты по биоэнергетике уверены, что имя человека влияет на его характер и во многом определяет судьбу. Именно поэтому так непросто найти подходящего партнера, с которым у вас сложится гармоничный союз, крепкая дружба или доверительные деловые отношения. Чтобы точно понимать, насколько идеально вы подходите друг другу, воспользуйтесь специальным сервисом совместимости имен Passion.ru, созданным совместно с лучшими нумерологами и астрологами страны. Просто выберите нужное имя из списка и нажмите кнопку!
          </Lead>
        </IndentWrap>
        <CompatibilityWidget />
        <IndentWrap
          top={spacing}
          left={isMobile ? 20 : 40}
          right={isMobile ? 20 : 60}
        >
          <Text>
            Наверняка каждый из вас при знакомстве с новым человеком хоть раз задумывался о том, насколько проще (и интереснее) была бы жизнь, если бы сразу можно было понять, перерастет ли ваше знакомство во что-то серьезное или останется на уровне мимолетного увлечения, о котором приятно будет впоследствии вспоминать. Наши предки тоже предпочитали не оставаться в неведении и верили, что имя человека отражает его характер и в какой-то степени определяет дальнейшую судьбу. Вот почему при рождении старались выбирать имена, имеющие особое значение, оберегающие ребенка и направляющие его в дальнейшем по жизни. Поэтому, чтобы не гадать, можно просто узнать имя вашего объекта симпатии и сопоставить с тем, насколько вы подходите друг другу.
          </Text>
          <VerticalIndent indent={20} />
          <Text>
            До тех пор, пока вы остаетесь в неведении, вы так и будете ошибаться дальше, выбирая тех, с кем вам не по пути. Ведь зачастую бывает так, что партнеры, которые идеально уживаются под одной крышей, абсолютно не находят общий язык в постели, или, наоборот, любовники, понимающие друг друга с полуслова, совершенно не могут долго находиться в одном пространстве. А бывает, что вы встречаете, казалось бы, мужчину всей своей жизни, но со временем понимаете, что крепкая дружба с ним намного ценнее, чем романтические отношения. Как бы там ни было, но, чтобы не тратить свое время и силы, воспользуйтесь нашими специальными подсказками, благодаря которым вы без труда определите, на что вам можно рассчитывать в ближайшем будущем с этим человеком. Или узнаете, что никакое совместное будущее вам не светит и вообще лучше держаться подальше от предмета вашей страсти.
          </Text>
          <VerticalIndent indent={20} />
          <Text>
            Достаточно лишь выбрать ваше имя и указать имя вашего избранника, чтобы не только получить точный ответ о вашей совместимости в любви и браке, сексуальной связи, партнерских отношениях или крепкой дружбе, но и понять, насколько перспективны взаимоотношения с ним. Заодно вы сможете узнать, какие мужские и женские имена идеально совместимы практически во всех сферах жизни, на какие стороны характера вашего избранника следует обратить внимание, чтобы избежать ссор и конфликтов в отношениях, какие ваши сильные качества помогут вам завоевать (или удержать) ваш предмет срасти, а заодно понять, хотите ли вы дальше быть с этим человеком, подходит ли он вам по определенным параметрам.
          </Text>
          <VerticalIndent indent={20} />
          <Text>
            Все самые распространенные имена, подобно знакам зодиака, разделены на двенадцать характеров, разнообразные сочетания которых дают полное представление о ваших текущих взаимоотношениях и дальнейших вариантах развития событий. Благодаря такой компоновке вы легко сможете определить свой тип взаимоотношений, понять, какие темы вам следует обходить при общении с избранником и на какие стороны другу друга необходимо обратить особое внимание, чтобы у вас получился идеальный союз, о котором вы мечтали. Если совместимость ваших имен оказалась высокой, то вам можно спокойно наслаждаться общением с вашим партнером, а если вдруг совместимость окажется низкой, то это лишний повод задуматься, с тем ли человеком вы планируете связать свою судьбу или вам лучше найти более подходящего кандидата.
          </Text>
          <NamesList
            items={maleNames}
            title='Мужские имена'
            sex='male'
          />
          <NamesList
            items={femaleNames}
            title='Женские имена'
            sex='female'
          />
        </IndentWrap>
      </BasePage>
    </Page>
  );
};

Compatibility.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default skip(withBreakpoint(Compatibility));
