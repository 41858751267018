import Scooter from 'core/components/Scooter';
import Link from 'core/components/Link';

export default function SiteScooter() {
  return (
    <Scooter
      services={[
        { link: '/about', content: 'Редакция' },
        { link: '/special', content: 'Спецпроекты' },
        { link: '/legal', content: 'Правовая информация' },
        { link: '/legal/terms-of-use', content: 'Условия использования' },
        { link: '/legal/privacy', content: 'Политика конфиденциальности' },
      ]}
      commercial={[
        'Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только  с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.',
        {
          body: <Link type='tertiary' to='/rules-recommenders'>На информационном ресурсе применяются рекомендательные технологии в соответствии с Правилами</Link>,
        },
      ]}
      contacts={{
        site: 'Passion',
        age: 18,
        phone: {
          caption: 'Телефон редакции:',
          body: '+7 (495) 785-17-00',
        },
      }}
    />
  );
}
