import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withPageHocs from 'core/components/withPageHocs';
import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';

import { rawAuthorsFetcher } from 'core/fetchers/authorsPage';

import resolve from 'core/resolver/resolve';

import { VERTICAL, VERTICAL_MOBILE } from 'site/components/Indents';
import BasePage from 'site/components/BasePage';
import { IndentWrap } from 'site/components/Wrappers';


function Authors({ rawAuthors, isMobile }) {
  const spacing = isMobile ? 20 : VERTICAL;
  const sideSpacings = {
    right: isMobile ? spacing : 0,
    left: isMobile ? spacing : 0,
  };

  return (
    <BasePage>
      <IndentWrap
        top={spacing}
        {...sideSpacings}
      >
        <IndentWrap bottom={isMobile ? VERTICAL_MOBILE : VERTICAL}>
          <H1>Авторы</H1>
        </IndentWrap>
        <AuthorsPage rawAuthors={rawAuthors} />
      </IndentWrap>
    </BasePage>
  );
}

Authors.propTypes = {
  rawAuthors: PropTypes.object,
  isMobile: PropTypes.bool,
};

const dataProvider = resolve({
  rawAuthors: rawAuthorsFetcher(),
});

export default withPageHocs(dataProvider)(withBreakpoint(Authors));
