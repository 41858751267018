import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import SmartImage from 'core/components/SmartImage';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Quote from 'site/components/Quote';

import styles from './index.styl';

export default function Opinion(props) {
  const {
    block,
    parentProps: {
      theme,
    },
  } = props;

  const body = get(block, 'attributes.body');
  const name = get(block, 'attributes.author.attributes.name');
  const job = get(block, 'attributes.author.attributes.job_title');
  const image = get(block, 'attributes.author.attributes.avatar.attributes.versions.original');

  return (
    <div className={styles.opinion}>
      <style jsx>{`
        .${styles.opinion}
          font-family ${theme.fonts.nuance}
          border-left 2px solid ${theme.colors.active1}

        .${styles.job}
          color ${theme.colors.hint}
      `}
      </style>
      {body && (
        <div className={styles.body}>
          <Quote>
            <MarkdownWrapper>
              {body}
            </MarkdownWrapper>
          </Quote>
        </div>
      )}
      <div className={styles.author}>
        {image && (
          <div className={styles.authorImage}>
            <SmartImage
              src={image}
              width={60}
              height={60}
            />
          </div>
        )}
        <div className={styles.info}>
          {name && (
            <div className={styles.name}>
              <MarkdownWrapper>
                {name}
              </MarkdownWrapper>
            </div>
          )}
          {job && (
            <div className={styles.job}>
              <MarkdownWrapper>
                {job}
              </MarkdownWrapper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Opinion.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
