import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import bindProps from 'core/components/bindProps';
import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';

import cutDown from 'core/utils/cut-down';

import Button180 from 'site/components/Button180';
import TitleCard from 'site/components/TitleCardM';

import { cardDateFormat } from 'site/utils';
import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'announce',
  'published_at',
  'topic_type',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});


function Card3(props) {
  const {
    type,
    content,
    isMobile,
    theme,
  } = props;

  const {
    showTime,
    showAnnounce,
    showBackground,
    showButton,
    altIcon,
    imageSize = 100,
    additionalPadding = '0px',
    trimTitle,
    headlineWeight,
    imageRatio,
    borderRadius,
    rounded,
    rowReverse,
  } = mapTypes[type] || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
    announce,
    published_at: published,
    topic_type: topicType,
  } = content.attributes;

  const {
    icons: {
      Video,
      VideoAlt,
      Photo,
      PhotoAlt,
    },
  } = theme;

  const mapIconByType = {
    video: altIcon ? VideoAlt : Video,
    gallery: altIcon ? PhotoAlt : Photo,
  };

  const Icon = mapIconByType[topicType];

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const iconSize = isMobile ? 20 : 25;

  const coverSize = isMobile ? 80 : imageSize;

  return (
    <div className={cx('card3', showBackground && styles._showBackground)}>
      <style jsx>{`
        .card3
          border-radius ${borderRadius}px
          padding ${additionalPadding}
          &.${styles._showBackground}
            background-color ${theme.colors.card}
        .${styles.time}
          font 400 14px/16px ${theme.fonts.display}
          color ${theme.colors.active1}

        .imageWrapper
          width ${coverSize}px
          &._withSameSides
            height ${coverSize}px

        .${styles.image}
          &:after
            transition opacity ${theme.animations.hover}

        .${styles.announce}
          color ${theme.colors.primary}
      `}</style>

      <Link
        to={link}
        type='secondary'
        className={cx(styles.link, rowReverse && styles._reversed)}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        <div className={cx('imageWrapper', rounded && styles._roundedImage, imageRatio === 1 && '_withSameSides')}>
          <div className={styles.image}>
            <Image
              {...cover && { src: cover }}
              {...previewCover && { previewSrc: previewCover }}
              {...remoteImage && { url: remoteImage }}
              width={coverSize}
              aspectRatio={imageRatio}
            />
          </div>
          {Icon && (
            <Icon
              className={styles.icon}
              width={iconSize}
              height={iconSize}
            />
          )}
        </div>
        <div className={styles.texts}>
          <TitleCard fontWeight={headlineWeight}>
            {trimTitle
              ? cutDown((listHeadline || headline), trimTitle, true)
              : (listHeadline || headline)
            }
          </TitleCard>
          {showTime && (
            <div className={styles.time}>
              {cardDateFormat(published)}
            </div>
          )}
          {showAnnounce && announce && (
            <div className={styles.announce}>
              {cutDown(announce)}
            </div>
          )}
          {showButton && (
            <div className={styles.button}>
              <Button180>Продолжить</Button180>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

Card3.defaultProps = {
  type: 0,
};

Card3.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  theme: themePropTypes(`
    {
      icons: {
        Video,
        Photo,
      },
    },
  `),
};

const Card = cardHOC(Card3);

export const Card3Type1 = bindProps({ type: 1 })(Card);
export const Card3Type2 = bindProps({ type: 2 })(Card);
export const Card3Type3 = bindProps({ type: 3 })(Card);
export const Card3Type4 = bindProps({ type: 4 })(Card);
export const Card3Type5 = bindProps({ type: 5 })(Card);
export const Card3Type6 = bindProps({ type: 6 })(Card);
export const Card3Type7 = bindProps({ type: 7 })(Card);
export const Card3Type8 = bindProps({ type: 8 })(Card);

Card.displayName = 'Card3';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card3 as StorybookComponent };
