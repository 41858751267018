import H1 from 'core/components/H1';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import ErrorPage from 'core/components/ErrorPage';

import Title2 from 'site/components/Title2';
import { IndentWrap } from 'site/components/Wrappers';
import BasePage from 'site/components/BasePage';

function NotFoundPage() {
  return (
    <ErrorPage>
      <BasePage>
        <div style={{ textAlign: 'center' }}>
          <IndentWrap top={100} bottom={100}>
            <H1>404</H1>
            <IndentWrap top={20} bottom={20}>
              <Title2>Такой страницы нет</Title2>
            </IndentWrap>
            <Link to='/'>
              <Button>Вернуться на главную</Button>
            </Link>
          </IndentWrap>
        </div>
      </BasePage>
    </ErrorPage>
  );
}

export default NotFoundPage;
