import PropTypes from 'prop-types';

import CoreShapka from 'core/components/Shapka';


export default function Shapka({ isSecondShapkaForPremium }) {
  return (
    <CoreShapka
      isSecondShapkaForPremium={isSecondShapkaForPremium}
      fullwidth={isSecondShapkaForPremium}
    />
  );
}

Shapka.propTypes = {
  isSecondShapkaForPremium: PropTypes.bool,
};
