import cx from 'classnames';
import withTheme from 'core/components/theme';

import GlobalStyles from '../global';
import styles from './index.styl';

export default function ListingSpecRubric(Component) {
  return withTheme(({ ...props }) => (
    <div className={cx(styles['listing-spec-rubric'], 'adv-native listing-spec-rubric')}>
      <GlobalStyles {...props} />
      <Component {...props} />
      <style jsx global>{`
        .listing-spec-rubric
          .ad_native_title
            font bold 20px/1.2 ${props.theme.fonts.display}
            :global(.mobile) &
              font bold 18px/1.2 ${props.theme.fonts.display}
            color ${props.theme.colors.primary}

          .ad_label__text
            font 12px/1.2 ${props.theme.fonts.nuance}
            color ${props.theme.colors.hint}
          .adv_info__text
            font 12px/1.2 ${props.theme.fonts.nuance}
      `}
      </style>
    </div>
  ));
}
