import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import SmartImage from 'core/components/SmartImage';

import styles from './index.styl';

export default function Recipe(props) {
  const {
    block,
    parentProps,
  } = props;

  const {
    theme,
    isMobile,
  } = parentProps;

  const {
    attributes: {
      calorific,
      caption,
      title,
      duration,
      ingredients,
      instructions,
    },
  } = block;

  return (
    <Fragment>
      <style jsx>{`
        .info
          font bold 16px/1 ${theme.fonts.nuance}
          color ${theme.colors.hint}
          margin-bottom 10px

          span + span
            margin-left 10px

        .ingredient-caption
          font-style italic
          color ${theme.colors.hint}

        .instruction-index
          color ${theme.colors.active1}
          font bold 36px/1 ${theme.fonts.display}
          margin 0 20px 5px 0

        .instruction-title
          color ${theme.colors.hint}
          font 16px/1.5 ${theme.fonts.nuance}
      `}
      </style>

      <div className={styles.title}>{title}</div>
      <div className='info'>
        <span>{duration}</span>
        <span>{calorific} ккал</span>
      </div>
      <div className={styles.caption}>{caption}</div>
      {ingredients.length > 0 && (
        <Fragment>
          <h3>Нам потребуется</h3>
          <ul className={styles.ingredients}>
            {ingredients.map(ingredient => {
              const {
                id: ingredientId,
                attributes: {
                  title: ingredientTitle,
                  caption: ingredientCaption,
                },
              } = ingredient;
              return (
                <li key={ingredientId} className={styles.ingredient}>
                  <span>{ingredientTitle}</span>
                  <span className='ingredient-caption'>{ingredientCaption}</span>
                </li>
              );
            })}
          </ul>
        </Fragment>
      )}
      {instructions.length > 0 && (
        <Fragment>
          <h3>Инструкция приготовления</h3>
          <ul className={styles.instructions}>
            {instructions.map((instruction, index) => {
              const {
                id: instructionId,
                attributes: {
                  caption: instructionCaption,
                  image: instructionImage,
                },
              } = instruction;

              const instructionImageSrc = get(instructionImage, 'attributes.versions.original');
              return (
                <li key={instructionId} className={styles.instruction}>
                  {instructionImageSrc && (
                    <div className={styles['instruction-image']}>
                      <SmartImage
                        width={isMobile ? 100 : 190}
                        height={isMobile ? 100 : 120}
                        src={instructionImageSrc}
                      />
                    </div>
                  )}
                  <div className={styles['instruction-text']}>
                    <div className={styles['instruction-header']}>
                      <span className='instruction-index'>{index + 1}.</span>
                      <span className='instruction-title'>{title}</span>
                    </div>
                    {instructionCaption}
                  </div>
                </li>
              );
            })}
          </ul>
        </Fragment>
      )}
    </Fragment>
  );
}

Recipe.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
