export const SOVMESTIMOST_LINK = '/sovmestimost-imen';

export const NAME_COMPATIBILITY = 'name_compatibility';

export const SOVMESTIMOST_TITLE = 'Совместимость имен женщины и мужчины в любви, браке, сексе, отношениях';
export const SOVMESTIMOST_DESCRIPTION = 'Сервис проверки совместимости мужских и женских имен в любви и браке, сексе и отношениях. Все о совместимости партнеров по имени на Passion';

export const SOVMESTIMOST_MALE_NAME_TITLE = 'Совместимость имени {name} с женскими именами';
export const SOVMESTIMOST_MALE_NAME_DESCRIPTION = 'Совместимость имени {name} с женскими именами в любви, сексе, отношениях и браке. Женские имена, совместимые с мужским именем {name}';

export const SOVMESTIMOST_FEMALE_NAME_TITLE = 'Совместимость имени {name} с мужскими именами';
export const SOVMESTIMOST_FEMALE_NAME_DESCRIPTION = 'Совместимость имени {name} с мужскими именами в любви, сексе, отношениях и браке. Мужские имена, совместимые с женским именем {name}';

export const MAIN_BLOCK_LIMIT = 5;

export const TODAY_PERIOD = '3';
