import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import resolve from 'core/resolver/resolve';

import {
  tagFetcher,
  topicsFetcher,
} from 'core/fetchers/tagTopics';

import withPageHocs from 'core/components/withPageHocs';
import TagTopics from 'core/components/TagTopics';

import BasePage from 'site/components/BasePage';
import Headline from 'site/components/Headline';

import { capitalize } from 'site/utils';

import Topics from '../rubric/Topics';


const TAG_LIMIT = 20;

function TagPage({ tag, tagTopics }) {
  return (
    <BasePage>
      <TagTopics
        tag={tag}
        topics={tagTopics}
        titleComponent={Headline}
        limit={TAG_LIMIT}
      >
        {({ topics, title, descriptionMeta }) => {
          return (
            <Fragment>
              <Helmet>
                <meta name='description' content={descriptionMeta || `${capitalize(title)} – последние статьи, новости, обзоры и другая свежая информация. Все материалы по теме «${title}» на Passion.ru.`} />
              </Helmet>
              <Topics topics={topics} />
            </Fragment>
          );
        }}
      </TagTopics>
    </BasePage>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  tagTopics: PropTypes.object,
};

const dataProvider = resolve({
  tag: tagFetcher(),
  tagTopics: topicsFetcher({
    include: Topics.include,
    fields: Topics.fields,
    limit: TAG_LIMIT,
  }),
});

export default withPageHocs(dataProvider)(TagPage);
