import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Recommender from 'core/components/Recommender';

import { withBreakpoint } from 'core/components/breakpoint';
import Drum from 'core/components/Drum';
import Feed from 'core/components/Feed';

import Title2 from 'site/components/Title2';
import Hr from 'site/components/Hr';
import { VERTICAL, VerticalIndent } from 'site/components/Indents';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';


function SiteRecommender({ isDesktop }) {
  const spacing = isDesktop ? VERTICAL : 20;

  return (
    <Fragment>
      <Hr indentBottom={20} indent={0} />
      <Title2>Популярное</Title2>
      <VerticalIndent indent={25} />
      <Recommender
        blockId={isDesktop ? '6ef4175f5c30429e9aa76ea26b0419ac' : 'b2229809cad4499594eb9d13e605b180'}
        renderer={isDesktop ? Drum : Feed}
        interitemSpacing={spacing}
        card={isDesktop ? Card2 : Card3}
        columns={3}
        grid
        limit={isDesktop ? 6 : 3}
      />
    </Fragment>
  );
}

SiteRecommender.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
