import PropTypes from 'prop-types';

import { get } from 'core/libs/lodash';

import cardHOC from 'core/components/card';
import Link from 'core/components/Link';
import Image from 'core/components/Image';
import bindProps from 'core/components/bindProps';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import Button from 'core/components/Button';

import mapTypes from './types';
import styles from './index.styl';

const requiredPayloadImports = ['image', 'opinion'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  opinion: {},
});

function CardExpert(props) {
  const {
    type,
    content,
    theme,
  } = props;

  const {
    showBody,
    showButton,
    showBottomLine,
    imageSize = 50,
  } = mapTypes[type] || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    opinion: {
      author,
      body,
    },
  } = relationships(content);

  const jobTitle = get(author, 'attributes.job_title');
  const name = get(author, 'attributes.name');
  const cover = get(author, 'attributes.avatar.attributes.versions.original');
  return (
    <div className={styles[`type${type}`]}>
      <style jsx>{`
        .${styles.content}
          border-bottom ${showBottomLine ? '1px solid ' + theme.colors.divider : 'none'}

        .${styles.body}
          color ${theme.colors.expertBody}
          font 500 16px/20px ${theme.fonts.text}

        .${styles.job}
          font 400 12px/15px ${theme.fonts.display}
          color ${theme.colors.grey}

        .name
          color ${theme.colors.black}
          font 700 16px/20px ${theme.fonts.display}

        .title
          font 700 22px/28px ${theme.fonts.display}
          color ${theme.colors.black}
          transition ${theme.animations.hover}
          :global(a):hover &
            color ${theme.colors.active1}
          .${styles.type0} &
            font 500 18px/23px ${theme.fonts.display}
            :global(.mobile) &
              font 500 16px/20px ${theme.fonts.display}
      `}</style>

      <Link to={link} type='secondary'>
        <div className={styles.content}>
          <div className={styles.author}>
            { cover && (
              <div className={styles.image}>
                <Image
                  src={cover}
                  aspectRatio={1}
                  width={imageSize}
                />
              </div>
            )}
            <div>
              <div className='name'>{name}</div>
              <div className={styles.job}>{jobTitle}</div>
            </div>
          </div>
          <div className={styles.texts}>
            <div className='title'>{listHeadline || headline}</div>
            {showBody && body && (
              <div className={styles.body}>{body}</div>
            )}
            {showButton && (
              <div className={styles.button}>
                <Button size='small'>Читать статью</Button>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

CardExpert.defaultProps = {
  type: 0,
};

CardExpert.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([0, 1]),
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
};

const Card = cardHOC(CardExpert);

export const CardExpertType1 = bindProps({ type: 1 })(Card);
export const CardExpertType2 = bindProps({ type: 2 })(Card);

Card.displayName = 'CardExpert';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardExpert as StorybookComponent };
